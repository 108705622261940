import { CheckmarkCircle20Px, CloseCircle20Px } from "@locaisolutions/icons";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Skeleton,
  Stack,
  Typography
} from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import { useAppDispatch, useAppSelector } from "~/app/store";
import { AutostoreBin } from "~/features/autostoreBin";
import {
  selectThisWorkstation,
  selectWorkstationAutostoreGridId
} from "~/redux/selectors/workstationsSelectors";
import { useConfirmInventoryMovementMutation } from "~/redux/warehouse/autostoreBinMaintenance.hooks";
import { usePostInventoryMoveMutation } from "~/redux/warehouse/inventory.hooks";
import { useVariantsByIdQuery } from "~/redux/warehouse/variant.hooks";
import { InventoryDto } from "~/types/api";

import { AdjustButton } from "./AdjustButton";
import { PulsingArrows } from "./PulsingArrows";
import {
  setSourceDestinationBins,
  setTaskState
} from "./binMaintenanceWorkstation.slice";

export function MoveInventoryButton({
  sourceBinInventory,
  disabled,
  portId,
  refetchBinInventory
}: {
  sourceBinInventory: InventoryDto[];
  disabled: boolean;
  portId: number;
  refetchBinInventory: () => void;
}) {
  const selectedWorkstation = useAppSelector(selectThisWorkstation);
  const autostoreGridId = useAppSelector(selectWorkstationAutostoreGridId);
  const { movementData } = useAppSelector(
    (state) => state.binMaintenanceWorkstation
  );
  const { inventoryMovements } = movementData || {};
  const { destinationBin, sourceBin } = inventoryMovements || {};
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const { data: variantsData, isLoading: isLoadingVariantsData } =
    useVariantsByIdQuery({
      variantIds: sourceBinInventory.map((bin) => bin.variantId)
    });

  const [postInventoryMove, { isLoading: inventoryMoveIsLoading }] =
    usePostInventoryMoveMutation();

  const [
    confirmInventoryMovement,
    { isLoading: confirmInventoryMovementIsLoading }
  ] = useConfirmInventoryMovementMutation();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const sourceCompartmentInventory =
    inventoryMovements &&
    sourceBinInventory.find(
      (bin) => bin.inventoryId === sourceBin?.inventoryId
    );
  const sourceCompartmentVariant = variantsData?.find(
    (variant) => variant.variantId === sourceCompartmentInventory?.variantId
  );
  const isFinalCompartmentToMove = sourceBinInventory.length === 1;
  const confirmMove = async () => {
    if (
      !sourceCompartmentInventory ||
      !autostoreGridId ||
      !selectedWorkstation ||
      !sourceBin ||
      !destinationBin?.warehouseBinId
    )
      return;
    await postInventoryMove({
      inventoryId: sourceCompartmentInventory?.inventoryId,
      targetBinId: destinationBin.warehouseBinId,
      quantity: sourceCompartmentInventory?.count
    });
    // after moving the inventory on final compartment, close the modal so user can unflag the source bin before confirmingInventoryMovement
    if (isFinalCompartmentToMove) {
      setIsModalOpen(false);
      refetchBinInventory();
      dispatch(setTaskState("ConfirmingMovement"));
      return;
    }
    const nextInventoryMovement = await confirmInventoryMovement({
      autostoreGridId,
      workstationId: selectedWorkstation.id,
      sourceBinId: sourceBin.autostoreBinId,
      destinationBinId: destinationBin.autostoreBinId
    }).unwrap();
    dispatch(setSourceDestinationBins(nextInventoryMovement));
    refetchBinInventory();
  };

  return (
    <>
      <Button
        size="large"
        onClick={() => setIsModalOpen(true)}
        disabled={disabled}
      >
        {t("move inventory")}
      </Button>
      <Dialog
        open={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        maxWidth="xl"
      >
        <DialogTitle>{t("move inventory")} </DialogTitle>
        <DialogContent sx={{ mt: 2 }}>
          <Typography variant="overline">{t("product name")}</Typography>
          {isLoadingVariantsData || !sourceCompartmentVariant ? (
            <Skeleton variant="text" width="50%" />
          ) : (
            <Typography variant="body1">
              {sourceCompartmentVariant?.variantName}
            </Typography>
          )}
        </DialogContent>
        {inventoryMovements && sourceCompartmentInventory && (
          <Stack direction="row" spacing={2} m={4} height="200px">
            <Stack direction="column" flex={1} minWidth="300px">
              <AutostoreBin
                state="Bin Opened"
                pickQuantity={sourceCompartmentInventory.count.value}
                pickCompartment={
                  inventoryMovements.sourceBin.compartmentNumber - 1
                }
                numberOfRows={
                  inventoryMovements.sourceBin.horizontalCompartmentCount
                }
                numberOfColumns={
                  inventoryMovements.sourceBin.verticalCompartmentCount
                }
              />
              <Typography alignSelf="center">
                {t("bin") + ` ${inventoryMovements.sourceBin.autostoreBinId}`}
              </Typography>
            </Stack>
            <Box pb={3} alignContent="center">
              <PulsingArrows />
            </Box>
            <Stack direction="column" flex={1} minWidth="300px">
              <AutostoreBin
                state="Bin Opened"
                pickQuantity={""}
                pickCompartment={
                  inventoryMovements.destinationBin.compartmentNumber - 1
                }
                numberOfRows={
                  inventoryMovements.destinationBin.horizontalCompartmentCount
                }
                numberOfColumns={
                  inventoryMovements.destinationBin.verticalCompartmentCount
                }
                moveTextCompartment={
                  inventoryMovements.destinationBin.compartmentNumber
                }
              />
              <Typography alignSelf="center">
                {t("bin") +
                  ` ${inventoryMovements.destinationBin.autostoreBinId}`}
              </Typography>
            </Stack>
          </Stack>
        )}
        <DialogActions sx={{ mb: 2, flexDirection: "column" }}>
          <Stack direction="row" mb={4}>
            {inventoryMovements && (
              <AdjustButton
                portId={portId}
                state="Bin Opened"
                binId={inventoryMovements?.sourceBin.autostoreBinId}
                numberOfRows={
                  inventoryMovements.sourceBin.horizontalCompartmentCount
                }
                numberOfColumns={
                  inventoryMovements.sourceBin.horizontalCompartmentCount
                }
                binInventory={sourceBinInventory}
                compartmentNumber={
                  inventoryMovements.sourceBin.compartmentNumber
                }
              />
            )}
          </Stack>
          <Stack justifyContent="center" direction="row" width="100%" gap={4}>
            <Button
              variant="subtle"
              size="large"
              startIcon={<CloseCircle20Px />}
              onClick={() => setIsModalOpen(false)}
            >
              {t("cancel")}
            </Button>
            <Button
              variant="contained"
              size="large"
              startIcon={<CheckmarkCircle20Px fill="white" />}
              onClick={confirmMove}
              disabled={
                inventoryMoveIsLoading || confirmInventoryMovementIsLoading
              }
            >
              {t("confirm")}
            </Button>
          </Stack>
        </DialogActions>
      </Dialog>
    </>
  );
}
