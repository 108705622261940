import { Bookmark20Px } from "@locaisolutions/icons";

import { Button } from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import { MaintenanceFlagModal } from "./MaintenanceFlagModal";

export const FlagBinButton = ({ portId }: { portId: number }) => {
  const { t } = useTranslation();
  const [isFlagBinModalOpen, setIsFlagBinModalOpen] = useState(false);

  return (
    <>
      <Button
        variant="subtle"
        size="large"
        startIcon={<Bookmark20Px />}
        onClick={() => setIsFlagBinModalOpen(true)}
      >
        {t("flag bin")}
      </Button>
      <MaintenanceFlagModal
        isOpen={isFlagBinModalOpen}
        onClose={() => setIsFlagBinModalOpen(false)}
        portId={portId}
      />
    </>
  );
};
