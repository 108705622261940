import { PropsWithChildren } from "react";
import { useLocation, Navigate } from "react-router-dom";

import useCloseWorkstationOnLocationChange from "~/hooks/useCloseWorkstationOnLocationChange";
import useModeUpdate from "~/hooks/useModeUpdate";
import { selectInterceptorReady } from "~/redux/selectors/appSelectors";
import { selectUsersFulfillmentCenter } from "~/redux/selectors/storeSelectors";

import { useAppSelector } from "./store";

export function PrivateRoute({
  children
}: PropsWithChildren): JSX.Element | null {
  const location = useLocation();
  const normalizedPath = location.pathname.toLowerCase();

  const isLogoutPath = normalizedPath === "/logout";
  const isSettingsPath = normalizedPath === "/settings";

  const isUserLoggedIn = useAppSelector((state) => state.login.isUserLoggedIn);
  const isInterceptorReady = useAppSelector(selectInterceptorReady);
  const usersFulfillmentCenter = useAppSelector(selectUsersFulfillmentCenter);
  const loginPath = useAppSelector((state) => state.site.loginPath) || "/login";

  // Post a mode string to warehouse based on path
  useModeUpdate({ pathname: location.pathname });
  useCloseWorkstationOnLocationChange();

  // the logout path is a special case in which we don't want to redirect when isUserLoggedIn is cleared
  if (isLogoutPath) {
    return <>{children}</>;
  }

  // redirect unauthenticated users to login
  if (!isUserLoggedIn) {
    return <Navigate to={loginPath} replace />;
  }

  // wait for the interceptor to be ready
  if (!isInterceptorReady) {
    return null;
  }

  // redirect users without a fulfillment center to settings
  if (!usersFulfillmentCenter && !isSettingsPath) {
    return <Navigate to="/settings" replace />;
  }

  // render the children components for all other cases
  return <>{children}</>;
}
