declare global {
  interface Window {
    simScan: (barcode: string) => void;
  }
}

export const setupSimScan = () => {
  window.simScan = (barcode: string): void => {
    const keys = ["Shift", "*", ...barcode.split(""), "Enter"];

    for (const key of keys) {
      window.document.dispatchEvent(new KeyboardEvent("keydown", { key }));
    }
  };
};

export const simScan = (barcode: string) => window.simScan(barcode);
