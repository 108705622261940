import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import ImportExportIcon from "@mui/icons-material/ImportExport";
import {
  Button,
  Collapse,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  List,
  ListItem,
  ListItemText,
  SelectChangeEvent,
  Stack,
  useMediaQuery
} from "@mui/material";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import {
  mobileWidth,
  ProgressButton,
  ConfirmationModal,
  formatUtcDayjsMaybe,
  globalDateFormat,
  RoundIconButton,
  useToast
} from "@qubit/autoparts";

import dayjs, { Dayjs } from "dayjs";
import {
  ChangeEvent,
  Dispatch,
  ReactNode,
  SetStateAction,
  useEffect,
  useState
} from "react";
import { useTranslation } from "react-i18next";
import { connect, ConnectedProps } from "react-redux";
import { useLocation } from "react-router-dom";

import { useAppDispatch, useAppSelector } from "~/app/store";

import {
  getVariantDisplayNameByDtoFe,
  inventoryDateLabel,
  isExpirationValid,
  getInventoryDateObjDayjs,
  checkIsExpiration,
  getHoldTypeOptions,
  holdTypeCheck,
  isAutostoreView,
  HoldType,
  HoldTypeSource
} from "~/lib/helpers";
import { mixpanelTrack, useMixpanelPageName } from "~/lib/mixpanel-tracking";
import {
  adjustInventory,
  generateInventoryReasonCodeOptions,
  clearInventoryToModify,
  getVariantData,
  placeInventoryHold,
  findInventoryByAutostoreBinNumber,
  deleteInventoryHold
} from "~/redux/actions/inventory";
import { InventoryHoldReasonCode } from "~/redux/public/inventory.openApi";
import { selectClientConfig } from "~/redux/selectors/siteSelectors";
import { selectUsersFulfillmentCenter } from "~/redux/selectors/storeSelectors";
import {
  selectSelectedPortId,
  selectThisWorkstation,
  selectWorkstationAutostoreGridId
} from "~/redux/selectors/workstationsSelectors";
import {
  VariantFrontendDto,
  InventorySummaryDto,
  AutostoreBinConfigurationDto,
  HoldDto,
  MeasuredValueDto
} from "~/types/api";

export type InventoryToModify = {
  binType: string;
  binId: Guid;
  inventoryId: Guid;
  locationName?: string;
  count?: MeasuredValueDto;
  committedCount?: MeasuredValueDto;
  expiration?: Date;
  manufactureDate?: Date | string;
  lotNumber: string;
  variantId: Guid;
  hold?: string;
  holds?: HoldDto[];
} | null;

const connector = connect(null, {
  adjustInventory,
  getVariantData,
  placeInventoryHold,
  findInventoryByAutostoreBinNumber,
  deleteInventoryHold
});

type PropsFromRedux = ConnectedProps<typeof connector>;

type InventoryAdjustDialogProps = PropsFromRedux & {
  selectedVariant: VariantFrontendDto | null;
  refreshCb: ({
    inventoryWasEmptied,
    newQuantity,
    inventoryId,
    newDate
  }: {
    inventoryWasEmptied?: boolean;
    newQuantity?: number;
    inventoryId?: Guid;
    newDate?: Date;
  }) => void;
  isCycleCountBlind?: boolean;
  // should be moved into a function that returns the hook & state?
  // maybe make a function that takes in parameters that can alter the state and execute the hook.
  hasInventoryAdjusted?: boolean;
  setHasInventoryAdjusted?: Dispatch<SetStateAction<boolean>>;
  onClose?: () => void;
  disabled?: boolean;
  invToModify: InventoryToModify;
  canceledReason: string;
  autostoreBinConfiguration?: AutostoreBinConfigurationDto | null;
  currentSelectedPortId?: number;
  isAdjustOnMain?: boolean;
  setCurrentSelectedInventoryRecord?: (
    selectedInventoryRecord: InventorySummaryDto
  ) => void;
  open: boolean;
  children?: ReactNode;
};

/**
 * An inventory adjust dialog shared across different pages.
 */
export function InventoryAdjustDialog(props: InventoryAdjustDialogProps) {
  const isMobile = useMediaQuery(mobileWidth);
  const trackedPageName = useMixpanelPageName();
  const { t } = useTranslation();
  const {
    invToModify,
    selectedVariant,
    disabled = false,
    onClose,
    isCycleCountBlind,
    hasInventoryAdjusted,
    setHasInventoryAdjusted,
    autostoreBinConfiguration,
    currentSelectedPortId,
    isAdjustOnMain,
    setCurrentSelectedInventoryRecord,
    open,
    children
  } = props;

  const { successToast, errorToast } = useToast();
  const dispatch = useAppDispatch();
  const selectedSummaries = useAppSelector(
    (state) => state.inventoryNew.selectedSummaries
  );
  const usersFulfillmentCenter = useAppSelector(selectUsersFulfillmentCenter);
  const selectedAutostoreGridId = useAppSelector(
    selectWorkstationAutostoreGridId
  );
  const selectedPortId = useAppSelector(selectSelectedPortId);

  const clientConfig = useAppSelector(selectClientConfig);

  const {
    inv_inventoryDateLabel,
    inv_inventoryDateRequired,
    ap_excludeRecalledHold
  } = clientConfig;

  const updatedInventoryToModify = useAppSelector(
    (state) => state.inventory.inventoryToModify
  );

  const workstation = useAppSelector(selectThisWorkstation);

  const [quantity, updateQuantity] = useState<number | null>(null);
  const [unitOfMeasure, updateUOM] = useState<string | null>(null);
  const [reasonCode, updateReasonCode] = useState<string>("Counted");
  const [inventoryDate, setInventoryDate] = useState<Dayjs | null>(null);
  const [updatedLotNumber, setUpdatedLotNumber] = useState<string | null>(null);
  const [areInventoryFieldsDirty, setAreInventoryFieldsDirty] = useState(false);
  const [areHoldFieldsDirty, setAreHoldFieldsDirty] = useState(false);

  const [isConfirmationModalOpen, setConfirmationModalOpen] = useState(false);
  const [isAdjustBtnDisabled, setIsAdjustBtnDisabled] = useState(false);

  const [availableHoldTypes, setAvailableHoldTypes] = useState<HoldType[]>([]);
  const [currentHoldTypes, setCurrentHoldTypes] = useState<HoldType[]>([]);

  const [expanded, setExpanded] = useState(open && !!currentHoldTypes.length);
  const [filteredHoldType, setFilteredHoldType] = useState<HoldType[]>([]);

  const isCycleCountsPage = useLocation().pathname.includes(
    "autostore-cyclecount"
  );

  // Do not adjust inventory on cycle counts v2.
  // For v2, the adjustment is handled on the backend.
  const canAdjustInventory = !useLocation().pathname.includes(
    "autostore-cyclecountv2"
  );

  const isAutostoreInventory = useLocation().pathname.includes(
    "autostore-inventory"
  );

  type ConfirmText = "confirm" | "confirm and adjust inventory" | "next bin";
  let confirmText: ConfirmText = "confirm";
  if (isCycleCountsPage && (isCycleCountBlind || areInventoryFieldsDirty)) {
    confirmText = "confirm and adjust inventory";
  } else if (isAutostoreInventory) {
    confirmText =
      areInventoryFieldsDirty ||
      areHoldFieldsDirty ||
      selectedSummaries?.length === 1
        ? "confirm"
        : "next bin";
  }

  const holdTypes = getHoldTypeOptions(ap_excludeRecalledHold);

  let quantityToDisplay: string | number = "";

  // set the display qty to the quantity or to the set value above.
  quantityToDisplay =
    typeof quantity === "number" ? quantity : quantityToDisplay;

  const clientFCReasonCodes =
    usersFulfillmentCenter?.clientConfiguration
      ?.clientSpecificInventoryAdjustmentReasonCodes;

  const isInputDateValid = isExpirationValid(
    inv_inventoryDateRequired || selectedVariant?.isExpirationRequired,
    inventoryDate
  );

  const hasUserClearedDate =
    !inventoryDate && !!updatedInventoryToModify?.expiration;

  // default holds section to expanded if selected inventory has holds
  useEffect(() => {
    if (open && currentHoldTypes.length) setExpanded(true);
  }, [currentHoldTypes, open]);

  useEffect(() => {
    if (open) setIsAdjustBtnDisabled(!isInputDateValid);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updatedInventoryToModify?.inventoryId, inventoryDate, open]);

  useEffect(() => {
    if (open && invToModify) {
      if (updatedInventoryToModify?.count && !isCycleCountBlind) {
        updateQuantity(updatedInventoryToModify?.count.value);
      }
      if (updatedInventoryToModify?.lotNumber) {
        setUpdatedLotNumber(updatedInventoryToModify.lotNumber);
      }
      if (checkIsExpiration(inv_inventoryDateLabel)) {
        setInventoryDate(
          updatedInventoryToModify?.expiration
            ? dayjs(updatedInventoryToModify.expiration)
            : null
        );
      } else if (!checkIsExpiration(inv_inventoryDateLabel)) {
        setInventoryDate(
          updatedInventoryToModify?.manufactureDate
            ? dayjs(updatedInventoryToModify.manufactureDate)
            : null
        );
      }
      updateUOM(updatedInventoryToModify?.count?.units || null);

      // sets the currentSelectedInventory in parent component to update the bin components selected compartment
      if (updatedInventoryToModify) {
        if (setCurrentSelectedInventoryRecord)
          setCurrentSelectedInventoryRecord(updatedInventoryToModify);
      }
    }

    if (!invToModify) {
      updateQuantity(0);
      updateReasonCode("Counted");
      setInventoryDate(null);
      updateUOM(null);
      setAreInventoryFieldsDirty(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    invToModify?.inventoryId,
    updatedInventoryToModify?.inventoryId,
    updatedInventoryToModify?.count?.value,
    updatedInventoryToModify?.expiration,
    updatedInventoryToModify?.manufactureDate,
    updatedInventoryToModify?.lotNumber,
    open
  ]);

  useEffect(() => {
    if (open) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises -- TODO: await this
      props.getVariantData({
        variantId: invToModify?.variantId,
        binId: invToModify?.binId,
        limit: 50,
        offset: 0,
        autostoreGridId: isAutostoreView(window.location.search)
          ? selectedAutostoreGridId
          : undefined,
        inventoryId: invToModify?.inventoryId
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const getFilteredHoldTypes = (currentHoldType: HoldType[]) => {
    let localFilteredHoldType = holdTypes.slice(); // Create a shallow copy of holdTypes
    if (currentHoldType && currentHoldType.length > 0) {
      localFilteredHoldType = localFilteredHoldType.filter(
        (hold) =>
          !currentHoldType
            .map((currentHold) => currentHold.reasonCode)
            .includes(hold.reasonCode)
      );
    }

    const localFilteredReasonCode = Array.from(
      new Set(
        localFilteredHoldType.map((localHoldType) => localHoldType.reasonCode)
      )
    );
    return {
      filteredHoldType: localFilteredHoldType,
      filteredReasonCode: localFilteredReasonCode
    };
  };

  const fetchCurrentHolds = async () => {
    let inventory = null;
    if (
      selectedAutostoreGridId &&
      updatedInventoryToModify?.autostoreBinNumber
    ) {
      inventory = await props.findInventoryByAutostoreBinNumber(
        selectedAutostoreGridId,
        updatedInventoryToModify?.autostoreBinNumber
      );
    }

    if (inventory && inventory.length > 0) {
      const mergedCurrentHolds = inventory
        .filter(
          (item) =>
            item.bin.autostoreCompartmentNumber ===
            updatedInventoryToModify?.autostoreCompartmentNumber
        )
        .flatMap((item) =>
          item.holds.map((hold) => ({
            reasonCode: hold.reasonCode as InventoryHoldReasonCode,
            source: hold.source as HoldTypeSource
          }))
        );
      // Rest of the code remains the same
      setAvailableHoldTypes(mergedCurrentHolds);
      setCurrentHoldTypes(mergedCurrentHolds);

      // Set the remaining hold types
      const { filteredHoldType: localFilteredHoldType } =
        getFilteredHoldTypes(mergedCurrentHolds);

      setFilteredHoldType(localFilteredHoldType);
    }
  };
  useEffect(() => {
    if (updatedInventoryToModify?.inventoryId && open) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises -- TODO: await this
      fetchCurrentHolds();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updatedInventoryToModify?.inventoryId, open]);

  const setInventoryDirty = () => {
    setAreInventoryFieldsDirty(true);
    if (setHasInventoryAdjusted) setHasInventoryAdjusted(true);
  };

  const handleQuantityChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setInventoryDirty();
    // allows single 0, with no trailing values, no special chars, a single white space.
    const validQuantity = /^(?:(?:[1-9]\d*)|0)?\s*$/.test(event.target.value);

    if (validQuantity) {
      if (event.target.value === "") {
        setIsAdjustBtnDisabled(true);
        updateQuantity(null);
        return;
      }
      updateQuantity(event.target.value ? Number(event.target.value) : null);
      setIsAdjustBtnDisabled(false);
    }
  };

  const handleReasonCodeChange = (event: SelectChangeEvent<unknown>) => {
    if (typeof event?.target?.value === "string") {
      setInventoryDirty();
      updateReasonCode(event.target.value);
    }
  };

  const handleAddHold = async (newReasonCode: string): Promise<void> => {
    if (newReasonCode && workstation && updatedInventoryToModify?.inventoryId) {
      await props.placeInventoryHold(
        updatedInventoryToModify.inventoryId,
        newReasonCode,
        workstation.id
      );
    }
  };

  const handleRemoveHold = async (oldReasonCode: string): Promise<void> => {
    if (
      oldReasonCode &&
      workstation &&
      updatedInventoryToModify?.inventoryId &&
      selectedAutostoreGridId &&
      selectedPortId
    ) {
      await props.deleteInventoryHold(
        updatedInventoryToModify.inventoryId,
        workstation.id,
        oldReasonCode,
        selectedAutostoreGridId,
        selectedPortId
      );
      successToast(t("inventory hold removed"));
    }
  };

  const closeDialog = () => {
    setAreInventoryFieldsDirty(false);
    setAreHoldFieldsDirty(false);
    setUpdatedLotNumber(null);
    dispatch(clearInventoryToModify());
    mixpanelTrack({
      trackedPageName,
      type: "Button Click",
      label: "Close Adjust Inventory Modal",
      eventProperties: {
        inventoryId: updatedInventoryToModify?.inventoryId
      }
    });
    if (onClose) onClose();
  };

  const handleConfirmClick = async (clearInventory = false): Promise<void> => {
    const updatedQty = clearInventory ? 0 : quantity;
    if (isCycleCountBlind && quantityToDisplay === "") {
      return;
    }
    if (
      canAdjustInventory &&
      (clearInventory || areInventoryFieldsDirty) &&
      updatedInventoryToModify
    ) {
      const { inventoryId, lotNumber } = updatedInventoryToModify;
      await props.adjustInventory({
        autostoreGridId: selectedAutostoreGridId,
        autostorePortId: currentSelectedPortId || selectedPortId || undefined,
        inventoryId,
        count: {
          units: updatedInventoryToModify.count?.units || "",
          value: updatedQty ?? (updatedInventoryToModify.count?.value as number)
        },
        lotNumber: updatedLotNumber || lotNumber,
        ...getInventoryDateObjDayjs(inv_inventoryDateLabel, inventoryDate),
        reasonCode,
        workstationId: workstation?.id
      });
      mixpanelTrack({
        trackedPageName,
        type: "Button Click",
        label: "Adjust Inventory Confirm",
        eventProperties: {
          inventoryId,
          count: {
            units: updatedInventoryToModify.count?.units || "",
            value:
              updatedQty ?? (updatedInventoryToModify.count?.value as number)
          },
          ...getInventoryDateObjDayjs(inv_inventoryDateLabel, inventoryDate),
          reasonCode
        }
      });
    }

    if (areHoldFieldsDirty && updatedQty !== 0) {
      for (const ht of currentHoldTypes.filter(
        (eht) => !availableHoldTypes.includes(eht)
      )) {
        await handleAddHold(ht.reasonCode);
        mixpanelTrack({
          trackedPageName,
          type: "Button Click",
          label: "Add Inventory Hold Confirm",
          eventProperties: {
            inventoryId: updatedInventoryToModify?.inventoryId,
            reasonCode: ht.reasonCode
          }
        });
      }
      for (const ht of availableHoldTypes.filter(
        (pht) => !currentHoldTypes.includes(pht)
      )) {
        try {
          await handleRemoveHold(ht.reasonCode);
          mixpanelTrack({
            trackedPageName,
            type: "Button Click",
            label: "Remove Inventory Hold Confirm",
            eventProperties: {
              inventoryId: updatedInventoryToModify?.inventoryId,
              reasonCode: ht.reasonCode
            }
          });
        } catch {
          // if removing the hold fails, refetch the holds to repopulate the ui list and exit the function
          await fetchCurrentHolds();
          return;
        }
      }
    }

    props.refreshCb({
      inventoryWasEmptied: updatedQty === 0,
      newQuantity: updatedQty ?? undefined,
      inventoryId: updatedInventoryToModify?.inventoryId,
      newDate: inventoryDate ? inventoryDate.toDate() : undefined
    });
    updateQuantity(null);
    updateReasonCode("Counted");
    setInventoryDate(null);
    if (onClose && window.location.pathname.includes("inventory")) {
      closeDialog();
    } else if (!isCycleCountsPage) {
      dispatch(clearInventoryToModify());
    }
  };

  const handleClearInventoryClick = (): Promise<void> | null => {
    setConfirmationModalOpen(true);
    return null;
  };

  const handleAddHoldToDropdownList = (newHoldType: HoldType) => {
    setCurrentHoldTypes([...currentHoldTypes, newHoldType]);
    setFilteredHoldType(
      filteredHoldType.filter(
        (hold) => hold.reasonCode !== newHoldType.reasonCode
      )
    );
    setAreHoldFieldsDirty(true);
  };

  const handleRemoveHoldFromDropdownList = (holdToRemove: HoldType) => {
    if (holdTypeCheck(holdToRemove, errorToast)) return;

    setCurrentHoldTypes(
      currentHoldTypes.filter(
        (hold: { reasonCode: InventoryHoldReasonCode }) =>
          hold.reasonCode !== holdToRemove.reasonCode
      )
    );

    setFilteredHoldType([...filteredHoldType, holdToRemove]);
    setAreHoldFieldsDirty(true);
  };

  const quantityTextField = (
    <TextField
      style={{ width: "100%" }}
      id="quantity"
      label={t("quantity")}
      type="text"
      variant="outlined"
      value={quantityToDisplay}
      onChange={handleQuantityChange}
      disabled={
        disabled || !updatedInventoryToModify || !updatedInventoryToModify.count
      }
      error={!quantityToDisplay && quantityToDisplay !== 0}
      inputProps={{
        role: "spinbutton",
        sx: {
          fontSize: "4rem",
          padding: "35px 10px 35px 18px"
        }
      }}
      InputLabelProps={{
        shrink: true
      }}
    />
  );

  const quantityButtons = (
    <Stack
      gap={1}
      sx={{
        position: "absolute",
        top: 20,
        right: 4
      }}
    >
      <RoundIconButton
        aria-label="increase quantity"
        onClick={(): void => {
          setInventoryDirty();
          updateQuantity(quantity ? quantity + 1 : 1);
        }}
        disabled={disabled}
        size="large"
      >
        <ExpandLessIcon
          style={{
            fontSize: 27
          }}
        />
      </RoundIconButton>

      <RoundIconButton
        aria-label="decrease quantity"
        onClick={(): void => {
          setInventoryDirty();
          updateQuantity(quantity ? quantity - 1 : 0);
        }}
        disabled={disabled}
        size="large"
      >
        <ExpandMoreIcon
          style={{
            fontSize: 27
          }}
        />
      </RoundIconButton>
    </Stack>
  );

  const reasonCodeField = (
    <TextField
      id="reasonCode"
      label={t("reason code")}
      type="string"
      select
      fullWidth
      variant="outlined"
      value={reasonCode || ""}
      InputLabelProps={{
        shrink: true
      }}
      inputProps={{
        sx: { fontSize: "1rem" }
      }}
      SelectProps={{
        onChange: handleReasonCodeChange
      }}
      disabled={disabled}
    >
      {generateInventoryReasonCodeOptions(clientFCReasonCodes).map(
        (choiceObj, i, arr) => (
          <MenuItem
            key={`uom-option-${choiceObj.value}`}
            className={`uom-option-${i}`}
            sx={{
              padding: 1,
              fontSize: "24px",
              borderBottomColor: "gray.light",
              borderBottom: i === arr.length - 1 ? "none" : `1px solid`
            }}
            value={choiceObj.value}
          >
            {choiceObj.label}
          </MenuItem>
        )
      )}
    </TextField>
  );

  const currentHoldTextField = (
    <List sx={{ width: "100%" }}>
      {currentHoldTypes.map((currentHold) => (
        <ListItem
          aria-label={currentHold.reasonCode}
          data-reasoncode={currentHold.reasonCode}
          key={currentHold.reasonCode}
          onClick={() => {
            handleRemoveHoldFromDropdownList(currentHold);
          }}
          secondaryAction={
            <IconButton
              edge="end"
              aria-label="delete"
              onClick={() => handleRemoveHoldFromDropdownList(currentHold)}
            >
              <HighlightOffIcon />
            </IconButton>
          }
          sx={{
            border: "1px solid",
            borderRadius: "4px",
            padding: 1,
            mb: 1
          }}
        >
          <ListItemText
            primary={
              <Typography variant="body1" sx={{ fontSize: "1rem" }}>
                {t(currentHold.reasonCode)}
              </Typography>
            }
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center"
            }}
          />
        </ListItem>
      ))}
    </List>
  );

  const filteredHoldTextField = (
    <TextField
      id="filteredHoldType"
      label={t("add hold")}
      type="string"
      select
      fullWidth
      variant="outlined"
      value=""
      InputLabelProps={{
        shrink: true
      }}
      inputProps={{
        sx: { fontSize: "1rem" }
      }}
      disabled={disabled}
    >
      {filteredHoldType.map((item, index) => (
        <MenuItem
          value={item.reasonCode.toLocaleLowerCase()}
          key={item.reasonCode}
          onClick={() => {
            handleAddHoldToDropdownList(item);
          }}
          data-reasoncode={item.reasonCode}
          style={{ padding: "1.5em 0 1.5em 0.5em" }}
          data-testid={`hold-type-option-${index}`}
        >
          {t(item.reasonCode)}
          <IconButton onClick={() => handleAddHoldToDropdownList(item)}>
            <AddCircleOutlineIcon />
          </IconButton>
        </MenuItem>
      ))}
    </TextField>
  );

  const unitOfMeasureField = (
    <TextField
      id="unitOfMeasure"
      label={t("uom")}
      type="string"
      fullWidth
      variant="outlined"
      value={unitOfMeasure || ""}
      disabled
      InputLabelProps={{
        shrink: true
      }}
      inputProps={{
        sx: {
          fontSize: 50,
          padding: "35px 10px 35px 18px",
          p: "35px 5px"
        }
      }}
    />
  );

  const inventoryHoldField = (
    <Button onClick={() => setExpanded(!expanded)}>
      {expanded ? (
        <>
          <Typography variant="overline" fontSize="18px" textTransform="none">
            {t("hide inventory hold")}
          </Typography>
          <ExpandLessIcon key="expand-less-icon" />
        </>
      ) : (
        <>
          <Typography variant="overline" fontSize="18px" textTransform="none">
            {t("inventory hold")}
          </Typography>
          <ExpandMoreIcon key="expand-more-icon" />
        </>
      )}
    </Button>
  );

  const isLotNumberRequiredAndEmpty =
    selectedVariant?.isLotNumberRequired && !updatedLotNumber;
  const hasLotNumberBeenCleared =
    !!updatedInventoryToModify?.lotNumber && !updatedLotNumber;

  const lotNumberField = (
    <TextField
      fullWidth
      variant="outlined"
      type="text"
      label={t("lot number")}
      value={updatedLotNumber || ""}
      onChange={(e) => {
        setInventoryDirty();
        setUpdatedLotNumber(e.target.value);
      }}
      error={isLotNumberRequiredAndEmpty || hasLotNumberBeenCleared}
    />
  );

  const inventoryDatePicker = (
    <DesktopDatePicker
      slotProps={{
        textField: {
          InputLabelProps: {
            shrink: true
          },
          label: t(inventoryDateLabel(inv_inventoryDateLabel)),
          variant: "outlined",
          fullWidth: true,
          error: !isInputDateValid || hasUserClearedDate
        }
      }}
      label={t(inventoryDateLabel(inv_inventoryDateLabel))}
      value={dayjs(formatUtcDayjsMaybe(inventoryDate))}
      minDate={checkIsExpiration(inv_inventoryDateLabel) ? dayjs() : undefined}
      format={globalDateFormat}
      onChange={(date) => {
        setInventoryDirty();
        if (
          isExpirationValid(
            inv_inventoryDateRequired || selectedVariant?.isExpirationRequired,
            date
          )
        ) {
          setIsAdjustBtnDisabled(false);
        } else {
          setIsAdjustBtnDisabled(true);
        }
        setInventoryDate(date);
      }}
      disabled={disabled}
    />
  );

  // default to Adjust text.
  // toggle between verify and adjust if counting-task is blind and if inventory state has been udpated
  let adjustmentButtonText = t("adjust");
  if (window.location.pathname.includes("autostore-cyclecountv2")) {
    if (!isCycleCountBlind && !hasInventoryAdjusted)
      adjustmentButtonText = t("verify");
  }

  const AdjustInventoryButton = (
    <ProgressButton
      id="adjust-button"
      data-testid="adjust-inventory-button"
      sx={{ backgroundColor: "primary.main", color: "white" }}
      buttonSize="medium"
      emphasis="high"
      responsive
      fullWidth
      startIcon={<ImportExportIcon />}
      disabled={isAdjustBtnDisabled || disabled || !isInputDateValid}
      onClick={() => handleConfirmClick()}
    >
      {adjustmentButtonText}
    </ProgressButton>
  );

  const ClearInventoryButton = (
    <ProgressButton
      id="clear-button"
      data-testid="clear-inventory-button"
      buttonSize="medium"
      emphasis="high"
      responsive
      fullWidth
      startIcon={<HighlightOffIcon />}
      onClick={(): Promise<void> | null => handleClearInventoryClick()}
      disabled={disabled}
      color="error"
    >
      {t("clear inventory")}
    </ProgressButton>
  );

  const ConfirmButton = (
    <ProgressButton
      id="confirm-button"
      color="primary"
      buttonSize="medium"
      emphasis="high"
      responsive
      fullWidth
      startIcon={<CheckIcon />}
      onClick={(): Promise<void> | null => handleConfirmClick()}
      disabled={
        disabled ||
        !isInputDateValid ||
        hasUserClearedDate ||
        typeof quantity !== "number" ||
        isLotNumberRequiredAndEmpty ||
        hasLotNumberBeenCleared
      }
    >
      {t(confirmText)}
    </ProgressButton>
  );

  const confirmationPrompt = `${t("are you sure you want to clear all")} ${
    updatedInventoryToModify?.count?.value || ""
  } ${updatedInventoryToModify?.count?.units || ""} ${t("of")} ${
    getVariantDisplayNameByDtoFe(selectedVariant) ?? ""
  } ${t("out of bin")} ${invToModify?.locationName || ""}?`;

  const getModalHeaderText = (): string => {
    // When the modal first opens 'autostoreBinConfiguration' is still being fetched
    // so we show a blank string to prevent showing one thing and then it changing
    if (
      !autostoreBinConfiguration?.numberOfCompartments ||
      !updatedInventoryToModify?.autostoreBinNumber
    ) {
      return "";
    }

    const binTranslation = t("bin");

    return autostoreBinConfiguration.numberOfCompartments === 1 ||
      !updatedInventoryToModify?.autostoreCompartmentNumber
      ? `${binTranslation} ${updatedInventoryToModify.autostoreBinNumber}`
      : `${binTranslation} ${updatedInventoryToModify.autostoreBinNumber} - ${updatedInventoryToModify.autostoreCompartmentNumber}`;
  };

  const headerText = getModalHeaderText();

  return (
    <Dialog
      open={open}
      onClose={closeDialog}
      maxWidth="tablet"
      aria-labelledby="InventoryAdjustDialog-header-text"
    >
      {!!headerText && (
        <DialogTitle id="InventoryAdjustDialog-header-text">
          {headerText}
        </DialogTitle>
      )}
      {onClose && (
        <Box sx={{ position: "absolute", right: 5, top: 15 }}>
          <IconButton onClick={closeDialog} aria-label="close">
            <CloseIcon
              sx={{
                color: "primary.dark"
              }}
            />
          </IconButton>
        </Box>
      )}

      <DialogContent>
        <Stack gap={2}>
          {updatedInventoryToModify?.productName && (
            <Stack gap={0}>
              <Typography variant="overline">{t("name")}</Typography>
              <Typography role="heading" variant="h5">
                {updatedInventoryToModify?.productName}
              </Typography>
            </Stack>
          )}
          <Stack direction="row" gap={2} alignItems="center">
            <Box position="relative">
              {quantityTextField}
              {!isMobile && invToModify?.count && quantityButtons}
            </Box>
            {children}
            {!children && unitOfMeasureField}
          </Stack>
          <Stack direction="row" gap={2}>
            {lotNumberField}
            {inventoryDatePicker}
          </Stack>
          {reasonCodeField}
          {isAdjustOnMain && (
            <Stack direction="row" gap={2}>
              {AdjustInventoryButton}
              {ClearInventoryButton}
            </Stack>
          )}
          {!isAdjustOnMain && (
            <>
              <Divider />
              <Box justifyContent="flex-start" width="fit-content">
                {inventoryHoldField}
                <Collapse in={expanded}>
                  <Stack>
                    {currentHoldTextField}
                    {filteredHoldTextField}
                  </Stack>
                </Collapse>
              </Box>
              {ConfirmButton}
            </>
          )}
        </Stack>
      </DialogContent>
      <ConfirmationModal
        isOpen={isConfirmationModalOpen}
        confirmCb={() => {
          // eslint-disable-next-line @typescript-eslint/no-floating-promises -- TODO: await this
          handleConfirmClick(true);
          setConfirmationModalOpen(false);
          setInventoryDirty();
        }}
        closeCb={() => setConfirmationModalOpen(false)}
        modalTitle={t("confirm clear")}
        modalText={confirmationPrompt}
      />
    </Dialog>
  );
}

export default connector(InventoryAdjustDialog);
