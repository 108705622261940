import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import SystemUpdateAltIcon from "@mui/icons-material/SystemUpdateAlt";
import { Grid, Menu, MenuItem } from "@mui/material";
import { styled } from "@mui/material/styles";
import { ProgressButton } from "@qubit/autoparts";
import { Dispatch, MouseEvent, SetStateAction } from "react";
import { useTranslation } from "react-i18next";

import { useAppSelector } from "~/app/store";
import { GetPortResponse } from "~/redux/actions";
import { selectClientConfig } from "~/redux/selectors/siteSelectors";
import { NextEmptyBinResponse, PutAwayTaskSummaryDto } from "~/types/api";

export const BinExceptionsMenuItem = styled(MenuItem)(() => ({
  width: "250px"
}));

type Props = {
  areAllPortsOpen: boolean;
  areAllPortsReady: boolean;
  binExceptionsMenuAnchor: HTMLElement | null;
  closeBinExceptionsMenu: () => void;
  currentSelectedBin: NextEmptyBinResponse | null;
  handleClickBinExceptionsButton: (
    event: MouseEvent<HTMLButtonElement>
  ) => void;
  handleClickBinNotEmptyButton: () => void;
  handleClickFlagBin: () => void;
  handleClickPlaceInventoryHold: () => void;
  isBinExceptionsMenuOpen: boolean;
  shouldListenToGridEvents: boolean;
  isPlaceHoldDisabled: boolean;
  isBinNotEmptyButtonDisabled: boolean;
  isPutawayButtonDisabled: boolean;
  portStateByPortArray: {
    getPortResponse: GetPortResponse;
    timestamp: Date;
  }[];
  selectedRow: PutAwayTaskSummaryDto | undefined;
  setChangeSuggestedBinModalOpen: Dispatch<SetStateAction<boolean>>;
  setIsPutawayModalOpen: Dispatch<SetStateAction<boolean>>;
};

export function ActionButtons(props: Props) {
  const {
    areAllPortsOpen,
    areAllPortsReady,
    binExceptionsMenuAnchor,
    closeBinExceptionsMenu,
    currentSelectedBin,
    handleClickBinExceptionsButton,
    handleClickBinNotEmptyButton,
    handleClickFlagBin,
    handleClickPlaceInventoryHold,
    isBinExceptionsMenuOpen,
    shouldListenToGridEvents,
    isPlaceHoldDisabled,
    isBinNotEmptyButtonDisabled,
    isPutawayButtonDisabled,
    portStateByPortArray,
    selectedRow,
    setChangeSuggestedBinModalOpen,
    setIsPutawayModalOpen
  } = props;
  const { t } = useTranslation();

  const clientConfig = useAppSelector(selectClientConfig);

  const { putaway_showExceptionsButton } = clientConfig;

  return (
    <>
      {!putaway_showExceptionsButton && (
        <Grid item sm={3} sx={{ textAlign: "center" }}>
          <ProgressButton
            id="bin-not-empty-button"
            data-testid="bin-not-empty-button"
            buttonSize="footer"
            emphasis="high"
            responsive
            variant="contained"
            fullWidth
            color="secondary"
            onClick={handleClickBinNotEmptyButton}
            disabled={isBinNotEmptyButtonDisabled}
          >
            {t("bin not empty")}
          </ProgressButton>
        </Grid>
      )}

      <Grid item sm={3} sx={{ textAlign: "center" }}>
        <ProgressButton
          id="putaway-button"
          data-testid="putaway-button"
          buttonSize="footer"
          emphasis="high"
          responsive
          variant="contained"
          color="primary"
          fullWidth
          startIcon={<SystemUpdateAltIcon style={{ fontSize: 22 }} />}
          onClick={(): Promise<void> | null => {
            setIsPutawayModalOpen((prev) => !prev);
            return null;
          }}
          disabled={isPutawayButtonDisabled}
        >
          {t("put away")}
        </ProgressButton>
      </Grid>

      {putaway_showExceptionsButton && (
        <Grid item sm={3} sx={{ textAlign: "center" }}>
          <ProgressButton
            id="bin-exceptions-button"
            data-testid="bin-exceptions-button"
            buttonSize="footer"
            emphasis="high"
            variant="contained"
            color="primary"
            sx={{
              backgroundColor: selectedRow ? "warning.main" : ""
            }}
            endIcon={<KeyboardArrowUpIcon style={{ fontSize: 22 }} />}
            onClick={handleClickBinExceptionsButton}
            disabled={!selectedRow}
            fullWidth
            responsive
          >
            {t("exceptions")}
          </ProgressButton>
          <Menu
            anchorEl={binExceptionsMenuAnchor}
            open={isBinExceptionsMenuOpen}
            onClose={closeBinExceptionsMenu}
            anchorOrigin={{
              vertical: "top",
              horizontal: "center"
            }}
            transformOrigin={{
              vertical: 200,
              horizontal: "center"
            }}
          >
            <BinExceptionsMenuItem
              disabled={!currentSelectedBin}
              onClick={() => {
                closeBinExceptionsMenu();
                setChangeSuggestedBinModalOpen(true);
              }}
            >
              {t("change config suggestion")}
            </BinExceptionsMenuItem>
            <BinExceptionsMenuItem
              disabled={
                (!areAllPortsReady && shouldListenToGridEvents) ||
                !areAllPortsOpen
              }
              onClick={handleClickBinNotEmptyButton}
            >
              {t("bin not empty")}
            </BinExceptionsMenuItem>
            <BinExceptionsMenuItem
              onClick={handleClickPlaceInventoryHold}
              disabled={isPlaceHoldDisabled}
            >
              {t("place inventory hold")}
            </BinExceptionsMenuItem>
            <BinExceptionsMenuItem
              onClick={handleClickFlagBin}
              disabled={
                !portStateByPortArray.length ||
                portStateByPortArray.every(
                  (port) => !port.getPortResponse.isReady
                )
              }
            >
              {t("flag bin")}
            </BinExceptionsMenuItem>
            <BinExceptionsMenuItem onClick={closeBinExceptionsMenu}>
              {t("product too large")}
            </BinExceptionsMenuItem>
          </Menu>
        </Grid>
      )}
    </>
  );
}

export default ActionButtons;
