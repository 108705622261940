import { ArrowDoubleRight20Px } from "@locaisolutions/icons";
import {
  Box,
  Container,
  Stack,
  SvgIcon,
  Typography,
  styled
} from "@mui/material";
import { useToast } from "@qubit/autoparts";
import { useEffect } from "react";

import { useTranslation } from "react-i18next";

import { useAppDispatch, useAppSelector } from "~/app/store";
import { useNavbar, ViewNameTranslation } from "~/hooks/useNavbar";

import { getMessageFromRtkError } from "~/lib/rtkErrorToMessage";
import {
  selectThisWorkstation,
  selectWorkstationAutostoreGridId,
  selectWorkstationAvailablePorts
} from "~/redux/selectors/workstationsSelectors";

import { useStartBinMaintenanceMutation } from "~/redux/warehouse/autostoreBinMaintenance.hooks";

import { useCloseWorkstationMutation } from "~/redux/warehouse/workstation.hooks";

import { BinMaintenancePort } from "./BinMaintenancePort";

import {
  setMovementData,
  setTaskState
} from "./binMaintenanceWorkstation.slice";

interface BinMaintenanceWorkstation {
  viewTitle: ViewNameTranslation;
}

const ActionHeader = styled(Box)(({ theme }) => ({
  margin: `${theme.spacing(14)} auto`,
  padding: theme.spacing(2),
  backgroundColor: theme.palette.primary.light,
  color: theme.palette.primary.main,
  width: "fit-content"
}));

export const BinMaintenanceWorkstation: React.FC<BinMaintenanceWorkstation> = (
  props
) => {
  const { viewTitle } = props;
  useNavbar({ viewTitle });
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { errorToast } = useToast();

  const selectedWorkstation = useAppSelector(selectThisWorkstation);
  const autostoreGridId = useAppSelector(selectWorkstationAutostoreGridId);
  const ports = useAppSelector(selectWorkstationAvailablePorts);
  const { movementTaskState } = useAppSelector(
    (state) => state.binMaintenanceWorkstation
  );

  const [closeWorkstation] = useCloseWorkstationMutation();
  const [startBinMaintenance, { isLoading: isStartBinMaintenanceLoading }] =
    useStartBinMaintenanceMutation();
  // initiate bin maintenance on landing
  useEffect(() => {
    if (!autostoreGridId || !selectedWorkstation?.id) return;
    const startInventoryMovements = async () => {
      try {
        await closeWorkstation({
          autostoreGridId,
          workstationId: selectedWorkstation?.id
        });
        const startMovementData = await startBinMaintenance({
          autostoreGridId,
          workstationId: selectedWorkstation?.id
        }).unwrap();
        dispatch(setMovementData(startMovementData));
      } catch (error) {
        errorToast(getMessageFromRtkError(error));
      }
    };
    void startInventoryMovements();
  }, [
    autostoreGridId,
    selectedWorkstation?.id,
    startBinMaintenance,
    errorToast,
    dispatch,
    closeWorkstation
  ]);

  // on unmount, reset the task state
  useEffect(
    () => () => {
      dispatch(setTaskState(null));
    },
    [dispatch]
  );

  let headerText = t("begin inventory move");
  switch (movementTaskState) {
    case "CleaningBin":
      headerText = t("unflag bin");
      break;
    case "MovingProduct":
      headerText = t("begin inventory move");
      break;
    case "ConfirmingMovement":
      headerText = t("unflag bin");
      break;
  }

  return (
    <Container maxWidth="xl">
      <Stack direction="column" width="1360px" justifySelf="center">
        <ActionHeader>
          <Stack direction="row">
            <SvgIcon
              component={ArrowDoubleRight20Px}
              sx={{ fontSize: "48px", alignSelf: "end" }}
            />
            <Typography variant="h3">{headerText}</Typography>
          </Stack>
        </ActionHeader>

        <Stack direction="row" justifyContent="space-between" spacing={10}>
          {ports.map((portId) => {
            return (
              <BinMaintenancePort
                key={portId}
                portId={portId}
                isLoading={isStartBinMaintenanceLoading}
              />
            );
          })}
        </Stack>
      </Stack>
    </Container>
  );
};
