import { AlertBanner } from "@qubit/autoparts";
import { skipToken } from "@reduxjs/toolkit/query";

import { useTranslation } from "react-i18next";

import { selectThisWorkstation } from "~/redux/selectors/workstationsSelectors";
import {
  useGetAutostoreGridConnectionModeQuery,
  useGetAutostoreGridStatusQuery
} from "~/redux/warehouse/autostoreGrid.hooks";

import { useAppSelector } from "./store";

export const GridConnection = () => {
  const { t } = useTranslation();
  const thisWorkstation = useAppSelector(selectThisWorkstation);
  const { data: gridConnection } = useGetAutostoreGridConnectionModeQuery(
    thisWorkstation ? thisWorkstation.autostoreGridId : skipToken
  );
  const { data: gridStatus } = useGetAutostoreGridStatusQuery(
    thisWorkstation ? thisWorkstation.autostoreGridId : skipToken,
    { pollingInterval: gridConnection?.connectionMode === "Active" ? 0 : 15000 }
  );

  return (
    <>
      {thisWorkstation &&
        gridStatus &&
        (gridStatus.kind === "error" || gridStatus.mode !== "Running") && (
          <AlertBanner
            severity="warning"
            sx={{
              width: "100%",
              whiteSpace: "pre-line",
              borderRadius: 0
            }}
          >
            {gridStatus.kind === "error"
              ? `${t("grid error")} ${gridStatus.autostoreFaultCode}: ${t(`autostoreFault.${gridStatus.autostoreFaultCode}`, { defaultValue: gridStatus.message })}`
              : `${t("grid mode")}: ${gridStatus.mode}`}
          </AlertBanner>
        )}
    </>
  );
};
