import { LanguageCode } from "~/localization_i18n";
import { StoreState } from "~/redux/reducers";

export const selectLanguageCode = (state: StoreState) =>
  state.site.languageCode;

export const selectLocale = (state: StoreState): LanguageCode => {
  const languageCode = selectLanguageCode(state);
  return languageCode === "bs" ? "en" : languageCode;
};

export const selectClientConfig = (state: StoreState) =>
  state.site.clientConfig;

export const selectUserMessages = (state: StoreState) =>
  state.site.userMessages;

export const selectCartAssignmentEnabled = (state: StoreState) =>
  state.site.clientConfig.cartAssignmentEnabled;

export const selectFusionPortEnabled = (state: StoreState) =>
  state.site.clientConfig.ap_fusionPortScreenEnabled;

export const selectPickToLightEnabled = (state: StoreState) =>
  state.site.clientConfig.ap_showPickToLight;

export const selectHomepages = (state: StoreState) =>
  state.site.clientConfig.fc_autostore_homepages;

export const selectInventoryDateLabelEnabled = (state: StoreState) =>
  state.site.clientConfig.inv_inventoryDateLabel;

export const selectAutomatedOperationsEnabled = (state: StoreState) =>
  state.site.automatedOperationsEnabled;

export const selectManualOpsEnabled = (state: StoreState) =>
  state.site.clientConfig.manualOpsEnabled;
