import CheckIcon from "@mui/icons-material/Check";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle
} from "@mui/material";
import { Stack } from "@mui/system";

import { useToast } from "@qubit/autoparts";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import { BinFlag } from "~/api/warehouseTypes/bin";
import { useAppDispatch, useAppSelector } from "~/app/store";

import { getMessageFromRtkError } from "~/lib/rtkErrorToMessage";

import {
  selectThisWorkstation,
  selectWorkstationAutostoreGridId
} from "~/redux/selectors/workstationsSelectors";

import { usePostFlagBinInventoryMovementsMutation } from "~/redux/warehouse/inventory.hooks";

import { MaintenanceFlagModalPort } from "./MaintenanceFlagModalPort";
import { setSourceDestinationBins } from "./binMaintenanceWorkstation.slice";

type MaintenanceFlagModal = {
  isOpen: boolean;
  onClose: () => void;
  portId: number;
};

export function MaintenanceFlagModal(props: MaintenanceFlagModal) {
  const { isOpen, onClose, portId } = props;

  // misc. hooks
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { errorToast, successToast } = useToast();

  // api hooks
  const [postFlagBinInventoryMovements] =
    usePostFlagBinInventoryMovementsMutation();

  // local state
  const [selectedFlag, setSelectedFlag] = useState<BinFlag | null>(null);

  // selectors
  const selectedGridId = useAppSelector(selectWorkstationAutostoreGridId);
  const selectedWorkstation = useAppSelector(selectThisWorkstation);

  // consts
  const flaggedReasons = ["dirty", "damaged", "misconfigured"] as BinFlag[];

  // functions
  const handleClose = () => {
    setSelectedFlag(null);
    onClose();
  };

  // use the movement/flag endpoint which will automatically select the destination bin and move to next task
  const flagDestinationBin = async () => {
    if (!selectedWorkstation || !selectedGridId || !selectedFlag) return;
    try {
      const nextInventoryMovement = await postFlagBinInventoryMovements({
        autostoreGridId: selectedGridId,
        reason: selectedFlag,
        workstationId: selectedWorkstation.id
      }).unwrap();
      dispatch(setSourceDestinationBins(nextInventoryMovement));
      successToast(t("flag set successfully"));
    } catch (error) {
      errorToast(getMessageFromRtkError(error));
    }
  };

  return (
    <Dialog open={isOpen} fullWidth maxWidth="sm" onClose={handleClose}>
      <DialogTitle>{t("flag bin")}</DialogTitle>
      <DialogContent sx={{ margin: "30px 30px 0" }}>
        <Stack direction="row" spacing={2}>
          <Stack
            direction="column"
            alignItems="center"
            justifyContent="space-evenly"
          >
            {flaggedReasons.map((option) => {
              const isButtonSelected = selectedFlag === option.toLowerCase();
              return (
                <Button
                  key={option}
                  fullWidth
                  startIcon={isButtonSelected ? <CheckIcon /> : null}
                  variant={isButtonSelected ? "contained" : "subtle"}
                  onClick={() =>
                    setSelectedFlag(isButtonSelected ? null : option)
                  }
                >
                  {t(option)}
                </Button>
              );
            })}
          </Stack>
          <MaintenanceFlagModalPort portId={portId} />
        </Stack>
      </DialogContent>
      <DialogActions sx={{ justifyContent: "center", padding: "30px" }}>
        <Button
          size="large"
          variant="contained"
          sx={{
            fontWeight: "normal"
          }}
          onClick={async () => {
            if (selectedWorkstation && selectedGridId) {
              await flagDestinationBin();
            }
            onClose();
          }}
          disabled={!selectedFlag}
        >
          {t("set flag")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
