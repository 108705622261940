import { Box, Stack } from "@mui/material";

import { AutostoreBin } from "~/features/autostoreBin";
import { usePortStatusQuery } from "~/hooks/usePortStatus";

export const MaintenanceFlagModalPort = ({ portId }: { portId: number }) => {
  const {
    horizontalCompartmentCount,
    verticalCompartmentCount,
    error,
    activityState,
    binState
  } = usePortStatusQuery(portId);
  const compartments = binState?.compartments;

  return (
    <Stack width={"100%"}>
      {!error && (
        <Box sx={{ position: "relative", padding: 2 }}>
          <Box
            sx={{
              position: "absolute",
              top: 0,
              bottom: 0,
              right: 0,
              left: 0,
              padding: 1,
              borderRadius: "0.5em",
              border: `0.625em solid`,
              borderColor: "primary.main"
            }}
          />
          <AutostoreBin
            state={activityState}
            binId={binState?.binState.binId}
            pickQuantity={0}
            pickCompartment={null}
            numberOfRows={horizontalCompartmentCount ?? 1}
            numberOfColumns={verticalCompartmentCount ?? 1}
            compartmentNumberWithStatuses={compartments?.map((compartment) => {
              const {
                autostoreCompartmentNumber,
                isAutostoreBinCompartmentEmpty
              } = compartment;
              return {
                autostoreCompartmentNumber,
                isAutostoreBinCompartmentEmpty
              };
            })}
          />
        </Box>
      )}
    </Stack>
  );
};
