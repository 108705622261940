import {
  AutostoreGridDto,
  AutostorePickingStateWithPortId,
  AutostoreTaskGroupInfoExtended,
  AvailableBinReportResponse,
  BinMaintenanceAssignment,
  BinMovementAssignment,
  CleaningReport,
  ConfirmInventoryMovementRequest,
  EmptyBinReportResponse,
  GetBinResponse,
  GetFlaggedBinsRequest,
  GridConnectionModeResponse,
  InventoryMovementBinNotEmptyRequest,
  InventoryMovementResponse,
  NextBinResponse,
  NextEmptyBinResponse,
  OpenByContentRequest,
  PortResponse,
  ShowPickToLightRequest,
  StartBinCleaningResponse,
  StartPickingOnWorkstationRequest,
  UnflagBinRequest
} from "~/types/api";

import { LogPublisherGridStateResponseTransformed } from "./autostoreGrid.hooks";
import { warehouseApi } from "./warehouseApi";

export interface SystemStatusConnected {
  kind: "connected";
  mode: string;
  stopCode: string | null;
}

export interface SystemStatusError {
  kind: "error";
  message: string;
  autostoreFaultCode?: number;
}

export type SystemStatus = { gridId: Guid } & (
  | SystemStatusConnected
  | SystemStatusError
);

type PostConfirmInventoryMovementParams = {
  autostoreGridId: string;
  workstationId: string;
  sourceBinId: number;
  destinationBinId: number;
};

export const autostoreGridApi = warehouseApi.injectEndpoints({
  endpoints: (build) => ({
    getNumberOfFlaggedBins: build.query<number, { autostoreGridId: string }>({
      query: ({ autostoreGridId }) => ({
        url: `/autostore-grid/${autostoreGridId}/task-group/flagged-moved`,
        method: "GET"
      }),
      providesTags: ["number flagged bins"]
    }),
    getBinsOutsideGrid: build.query<number[], { autostoreGridId: string }>({
      query: ({ autostoreGridId }) => ({
        url: `/autostore-grid/${autostoreGridId}/bins-with-mode/outside`,
        method: "GET"
      }),
      providesTags: ["bins outside grid"]
    }),
    postStartInventoryMovements: build.query<
      InventoryMovementResponse,
      { autostoreGridId: string; workstationId: string }
    >({
      query: ({ autostoreGridId, workstationId }) => ({
        url: `/autostore-grid/${autostoreGridId}/workstation/${workstationId}/start-inventory-movements`,
        method: "POST"
      }),
      providesTags: ["start inventory movements"]
    }),
    getInventoryMovementsState: build.query<
      BinMovementAssignment[],
      { autostoreGridId: string; workstationId: string }
    >({
      query: ({ autostoreGridId, workstationId }) => ({
        url: `/autostore-grid/${autostoreGridId}/workstation/${workstationId}/get-inventory-movements-state`,
        method: "GET"
      }),
      providesTags: ["inventory movements"]
    }),
    postConfirmInventoryMovement: build.mutation<
      InventoryMovementResponse,
      PostConfirmInventoryMovementParams
    >({
      query: ({
        autostoreGridId,
        workstationId,
        sourceBinId,
        destinationBinId
      }) => {
        const request: ConfirmInventoryMovementRequest = {
          sourceBinId,
          destinationBinId
        };

        return {
          url: `/autostore-grid/${autostoreGridId}/workstation/${workstationId}/confirm-inventory-movement`,
          method: "POST",
          body: request
        };
      },
      invalidatesTags: ["inventory movements", "number flagged bins"]
    }),
    postSkipInventoryMovement: build.mutation<
      InventoryMovementResponse,
      {
        autostoreGridId: string;
        workstationId: string;
      }
    >({
      query: ({ autostoreGridId, workstationId }) => ({
        url: `/autostore-grid/${autostoreGridId}/workstation/${workstationId}/skip-inventory-movement`,
        method: "POST"
      }),
      invalidatesTags: ["inventory movements"]
    }),
    postBinNotEmptyInventoryMovement: build.mutation<
      InventoryMovementResponse,
      InventoryMovementBinNotEmptyRequest
    >({
      query: ({ gridId, workstationId, productIdentifier, compartment }) => {
        const request = {
          GridId: gridId,
          WorkstationId: workstationId,
          ProductIdentifier: productIdentifier,
          Compartment: compartment
        };

        return {
          url: `/inventory/movement/bin-not-empty`,
          method: "POST",
          body: request
        };
      },
      invalidatesTags: ["inventory movements"]
    }),
    getAutostoreGrid: build.query<AutostoreGridDto, Guid>({
      query: (autostoreGridId) => ({
        url: `/autostore-grid/${autostoreGridId}`
      }),
      providesTags: ["autostore grid"]
    }),
    getAutostoreGrids: build.query<AutostoreGridDto[], void>({
      query: () => ({ url: "/autostore-grid" }),
      providesTags: ["autostore grid"]
    }),
    getAutostoreGridStatus: build.query<SystemStatus, Guid>({
      query: (autostoreGridId) => ({
        url: `/autostore-grid/${autostoreGridId}/system-status`
      }),
      providesTags: ["autostore grid status"]
    }),
    getAutostoreGridConnectionMode: build.query<
      GridConnectionModeResponse,
      Guid
    >({
      query: (autostoreGridId) => ({
        url: `/autostore-grid/${autostoreGridId}/connection-mode`
      }),
      providesTags: ["autostore grid connection mode"]
    }),
    editCleaningSchedule: build.mutation<
      void,
      {
        autostoreGridId: Guid;
        minCleaningTasks: number | null;
        maxCleaningTasks: number | null;
        cleaningCadenceInDays: number | null;
        maxBinFlaggedInventoryMoveTaskGroupSize: number | null;
      }
    >({
      query: ({ autostoreGridId, ...rest }) => ({
        url: `/autostore-grid/${autostoreGridId}`,
        method: "POST",
        body: rest
      }),
      invalidatesTags: (_, error) => (error ? [] : ["autostore grid"])
    }),
    getBinCleaningReport: build.query<CleaningReport, Guid>({
      query: (autostoreGridId) => ({
        url: `/autostore-grid/${autostoreGridId}/bin-cleaning-report`
      }),
      providesTags: ["bin cleaning report"]
    }),
    startBinCleaning: build.mutation<
      StartBinCleaningResponse,
      { autostoreGridId: Guid; workstationId: Guid }
    >({
      query: ({ autostoreGridId, workstationId }) => ({
        url: `/autostore-grid/${autostoreGridId}/workstation/${workstationId}/start-bin-cleaning/`,
        method: "POST"
      }),
      invalidatesTags: ["start bin cleaning"]
    }),
    reconfigureBinCompartments: build.mutation<
      void,
      {
        autostoreGridId: Guid;
        binNumber: number;
        configurationId: Guid;
        workstationId?: Guid;
      }
    >({
      query: ({
        autostoreGridId,
        binNumber,
        configurationId,
        workstationId
      }) => ({
        url: `/autostore-grid/${autostoreGridId}/bin/${binNumber}/reconfigure-compartments`,
        method: "POST",
        body: {
          configurationId,
          workstationId
        }
      }),
      invalidatesTags: ["bin configuration", "autostore bin"]
    }),
    getBin: build.query<
      GetBinResponse,
      { autostoreGridId: Guid; binNumber: number }
    >({
      query: ({ autostoreGridId, binNumber }) => ({
        url: `/autostore-grid/${autostoreGridId}/bin/${binNumber}`
      }),
      providesTags: ["autostore bin"]
    }),
    deleteBinFlag: build.mutation<
      void,
      {
        autostoreGridId: Guid;
        binNumber: number;
        reason: string;
        workstationId?: Guid;
      }
    >({
      query: ({ autostoreGridId, binNumber, reason, workstationId }) => {
        const request: UnflagBinRequest = { reason, workstationId };

        return {
          url: `/autostore-grid/${autostoreGridId}/bin/${binNumber}/flag`,
          method: "DELETE",
          body: request
        };
      },
      invalidatesTags: ["flagged bins"]
    }),
    postFlagBin: build.mutation<
      void,
      { autostoreGridId: Guid; binId: number; portId: number; reason: string }
    >({
      query: ({ autostoreGridId, portId, binId, reason }) => {
        const request = { reason };

        return {
          url: `/autostore-grid/${autostoreGridId}/port/${portId}/bin/${binId}/flag`,
          method: "POST",
          body: request
        };
      },
      invalidatesTags: ["flagged bins"]
    }),
    postFlagBinRemoved: build.mutation<
      void,
      { autostoreGridId: Guid; workstationId: Guid | null; binId: number }
    >({
      query: ({ autostoreGridId, workstationId, binId }) => {
        const request = { WorkstationId: workstationId };
        return {
          url: `/autostore-grid/${autostoreGridId}/bin/${binId}/flag-bin-removed`,
          method: "POST",
          body: request
        };
      },
      invalidatesTags: ["flagged bins"]
    }),
    getFlaggedBins: build.query<
      BinMaintenanceAssignment[],
      {
        autostoreGridId: Guid;
        workstationId?: Guid;
      }
    >({
      query: ({ autostoreGridId, workstationId }) => {
        const request: GetFlaggedBinsRequest = {
          workstationId
        };

        return {
          url: `/autostore-grid/${autostoreGridId}/get-flagged-bins`,
          method: "POST",
          body: request
        };
      },
      providesTags: ["flagged bins"]
    }),
    getPortStatus: build.query<
      PortResponse,
      { autostoreGridId: Guid; portId: number }
    >({
      query: ({ autostoreGridId, portId }) => ({
        url: `/autostore-grid/${autostoreGridId}/port/${portId}`
      }),
      providesTags: ["port status"]
    }),
    getAutostoreTasks: build.query<AutostoreTaskGroupInfoExtended[], Guid>({
      query: (autostoreGridId) => ({
        url: `/autostore-grid/${autostoreGridId}/query-task-extended`,
        method: "POST",
        body: {
          taskGroupId: null,
          minTaskGroupId: null,
          maxTaskGroupId: null,
          taskId: null
        }
      }),
      providesTags: ["autostore task group"]
    }),
    deleteTaskGroup: build.mutation<
      void,
      { autostoreGridId: Guid; taskGroupId: number }
    >({
      query: ({ autostoreGridId, taskGroupId }) => ({
        url: `/autostore-grid/${autostoreGridId}/task-group/${taskGroupId}`,
        method: "DELETE"
      }),
      invalidatesTags: ["autostore task group"]
    }),
    deleteTask: build.mutation<void, { autostoreGridId: Guid; taskId: number }>(
      {
        query: ({ autostoreGridId, taskId }) => ({
          url: `/autostore-grid/${autostoreGridId}/task/${taskId}`,
          method: "DELETE"
        }),
        invalidatesTags: ["autostore task group"]
      }
    ),
    closeBin: build.mutation<
      void,
      {
        autostoreGridId: Guid;
        portId: number;
        binNumber: number;
        taskId?: number;
      }
    >({
      query: ({ autostoreGridId, portId, binNumber, taskId }) => ({
        url: `/autostore-grid/${autostoreGridId}/port/${portId}/bin/${binNumber}/close`,
        method: "POST",
        body: {
          taskId: taskId ? taskId : undefined
        }
      }),
      invalidatesTags: ["port status", "autostore task group"]
    }),
    requestNextBin: build.mutation<
      NextBinResponse,
      {
        autostoreGridId: Guid;
        portId: number;
      }
    >({
      query: ({ autostoreGridId, portId }) => ({
        url: `/autostore-grid/${autostoreGridId}/port/${portId}/next-bin-with-bin-configuration`,
        method: "GET"
      }),
      invalidatesTags: ["port status", "autostore task group"]
    }),
    closePort: build.mutation<
      void,
      {
        autostoreGridId: Guid;
        portId: number;
      }
    >({
      query: ({ autostoreGridId, portId }) => ({
        url: `/autostore-grid/${autostoreGridId}/port/${portId}/close`,
        method: "POST"
      }),
      invalidatesTags: ["port status"]
    }),
    nextEmptyBin: build.mutation<
      NextEmptyBinResponse,
      {
        autostoreGridId: Guid;
        portId: number;
      }
    >({
      query: ({ autostoreGridId, portId }) => ({
        url: `/autostore-grid/${autostoreGridId}/port/${portId}/next-empty-bin`,
        method: "GET"
      }),
      invalidatesTags: ["port status"]
    }),
    skipInductionBin: build.mutation<
      NextEmptyBinResponse,
      {
        autostoreGridId: Guid;
        portId: number;
      }
    >({
      query: ({ autostoreGridId, portId }) => ({
        url: `/autostore-grid/${autostoreGridId}/port/${portId}/skip-induction-bin`,
        method: "POST"
      })
    }),
    openPort: build.mutation<
      void,
      {
        autostoreGridId: Guid;
        portId: number;
        categoryId: number;
      }
    >({
      query: ({ autostoreGridId, portId, categoryId }) => ({
        url: `/autostore-grid/${autostoreGridId}/port/${portId}/category/${categoryId}/open`,
        method: "POST"
      }),
      invalidatesTags: ["port status"]
    }),
    openPortForContent: build.mutation<
      void,
      {
        autostoreGridId: Guid;
        portId: number;
      } & OpenByContentRequest
    >({
      query: ({ autostoreGridId, portId, ...rest }) => ({
        url: `/autostore-grid/${autostoreGridId}/port/${portId}/specific-content/open`,
        method: "POST",
        body: rest
      }),
      invalidatesTags: ["port status"]
    }),
    showPickToLight: build.mutation<
      void,
      {
        autostoreGridId: Guid;
      } & ShowPickToLightRequest
    >({
      query: ({ autostoreGridId, ...rest }) => ({
        url: `/autostore-grid/${autostoreGridId}/port/show-pick-to-light`,
        method: "POST",
        data: rest
      }),
      invalidatesTags: []
    }),
    getLogPublisherState: build.query<
      LogPublisherGridStateResponseTransformed,
      Guid
    >({
      query: (autostoreGridId) => ({
        url: `/autostore-grid/${autostoreGridId}/log-publisher-state`
      })
    }),
    emptyBinReport: build.query<EmptyBinReportResponse[], Guid>({
      query: (autostoreGridId) => ({
        url: `/autostore-grid/${autostoreGridId}/empty-bin-report`
      }),
      providesTags: ["empty bin report"]
    }),
    availableBinReport: build.query<AvailableBinReportResponse[], Guid>({
      query: (autostoreGridId) => ({
        url: `/autostore-grid/${autostoreGridId}/available-bin-report`
      }),
      providesTags: ["available bin report"]
    }),
    startPickingOnWorkstation: build.mutation<
      AutostorePickingStateWithPortId[],
      StartPickingOnWorkstationRequest & { gridId: Guid; workstationId: Guid }
    >({
      query: ({ gridId, workstationId, ...body }) => {
        return {
          url: `/autostore-grid/${gridId}/workstation/${workstationId}/start-picking-on-workstation`,
          method: "POST",
          body
        };
      }
    }),
    syncGridState: build.query<string, { gridId: Guid }>({
      query: ({ gridId }) => {
        return {
          url: `/autostore-grid/${gridId}/sync-grid-state`
        };
      }
    })
  }),
  overrideExisting: false
});
