import { StoreState } from "~/redux/reducers";

export const selectClientIdReady = (state: StoreState) =>
  state.app.clientIdReady;
export const selectFlagsReadyClientId = (state: StoreState) =>
  state.app.flagsReady.clientId;
export const selectFlagsReadyFcId = (state: StoreState) =>
  state.app.flagsReady.fcId;
export const selectInterceptorReady = (state: StoreState) =>
  state.app.interceptorReady;
export const selectClientConfigReady = (state: StoreState) =>
  state.app.clientConfigReady;
