import WorkstationIcon from "@locaisolutions/icons/dist/icons20px/Workstation20Px";
import { Button } from "@mui/material";
import { useToast } from "@qubit/autoparts";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { useAppDispatch, useAppSelector } from "~/app/store";

import { parentPortIdFoundInWorkstation } from "~/lib/helpers";
import { getMessageFromRtkError } from "~/lib/rtkErrorToMessage";
import {
  shapePickingState,
  StartPickingOnWorkstationSuccessAction
} from "~/redux/actions";
import {
  selectThisWorkstation,
  selectWorkstationAvailablePorts
} from "~/redux/selectors/workstationsSelectors";

import { usePickBatchMutation } from "~/redux/warehouse/autostorePicking.hooks";

import { PickBatchModal } from "./PickBatchModal";

type PickBatchButtonProps = {
  batchId: Guid;
  disabled?: boolean;
};
export const PickBatchButton = ({
  batchId,
  disabled
}: PickBatchButtonProps) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { errorToast } = useToast();
  const [startPickingBatch] = usePickBatchMutation();
  const siteWorkstation = useAppSelector(selectThisWorkstation);
  const workstationId = siteWorkstation?.id;
  const gridId = siteWorkstation?.autostoreGridId;
  const workstationPortId = useAppSelector(selectWorkstationAvailablePorts).at(
    0
  );
  const { t } = useTranslation();

  const enableMultiPort = parentPortIdFoundInWorkstation(
    siteWorkstation?.ports
  );

  const [pickModalOpen, setPickModalOpen] = useState(false);

  const handlePickBatch = useCallback(async () => {
    if (!gridId || !workstationId || workstationPortId === undefined) {
      // TODO: Ensure workstation role is also correct
      errorToast("Workstation not set or port is missing");
      return;
    }
    try {
      const response = await startPickingBatch({
        gridId,
        workstationId,
        portId: workstationPortId,
        batchId,
        enableMultiPort
      }).unwrap();

      const chosenState =
        response.find((s) => s.pickingState.batchId === batchId) ?? response[0];
      const portId = chosenState.portId;
      const pickingState = chosenState.pickingState;

      dispatch<StartPickingOnWorkstationSuccessAction>({
        type: "autostore/START_PICKING_ON_WORKSTATION_SUCCESS",
        payload: {
          portId,
          pickingState: shapePickingState(pickingState),
          timestamp: new Date()
        }
      });
      navigate(
        `/autostore-pick?autostore=true&batchId=${pickingState.batchId}`
      );
    } catch (err) {
      errorToast(getMessageFromRtkError(err));
    }
  }, [
    gridId,
    workstationId,
    errorToast,
    startPickingBatch,
    workstationPortId,
    batchId,
    dispatch,
    navigate,
    enableMultiPort
  ]);

  return (
    <>
      <Button
        color="secondary"
        sx={{ fontWeight: "700", minWidth: "max-content" }}
        onClick={() => setPickModalOpen(true)}
        startIcon={<WorkstationIcon fill="white" />}
        disabled={disabled}
      >
        {t("assign to my workstation")}
      </Button>
      {pickModalOpen && (
        <PickBatchModal
          pickBatch={handlePickBatch}
          closeModal={() => setPickModalOpen(false)}
        />
      )}
    </>
  );
};
