import ErrorIcon from "@locaisolutions/icons/dist/icons24px/Error24Px";
import { Box, Typography, Stack, useTheme, styled } from "@mui/material";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useParams, useLocation } from "react-router-dom";

import { resetAppReady } from "~/app/app.slice";
import { useAppDispatch, useAppSelector } from "~/app/store";
import AutoStoreLogo from "~/assets/img/autostore-dots-and-logo.svg?react";
import warehouseImage from "~/assets/img/autostore-warehouse.webp";
import { OrganizationLoginButton } from "~/features/organizationLoginButton";

import { setLoginPath, clearLoginState } from "./login.slice";

const loginButtonStyles = {
  width: 210,
  height: 72,
  fontSize: "21px",
  borderRadius: "15px",
  py: "15px",
  mt: "66px"
};

const ErrorContainer = styled(Box)`
  display: flex;
  align-items: center;
  gap: 6px;
  margin: 28px 0 0 6px;
`;

/**
 *  Login page displayed when a user navigates to /login/:name.
 *  For all clients except BevClient.
 */
function OrganizationLogin() {
  const { name } = useParams<{ name: string }>();
  const location = useLocation();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { palette } = useTheme();

  const errorMessage = useAppSelector((state) => state.login.errorMessage);

  useEffect(() => {
    dispatch(setLoginPath(location.pathname));
    dispatch(resetAppReady());
    dispatch(clearLoginState());
  }, [dispatch, location.pathname]);

  return (
    <Box
      display="grid"
      gridTemplateColumns="2fr 3fr"
      height="100vh"
      width="100vw"
    >
      <Stack justifyContent="center" alignContent="center" m="48px 96px">
        <AutoStoreLogo
          style={{ position: "absolute", top: "48px", left: "96px" }}
        />
        <Typography fontSize="36px" lineHeight={1}>
          {t("welcome to")}
        </Typography>
        <Typography fontSize="108px" fontWeight={500} lineHeight={1} mt="18px">
          Qubit
        </Typography>
        <OrganizationLoginButton
          sx={loginButtonStyles}
          organizationName={name}
        />

        {errorMessage && (
          <ErrorContainer>
            <ErrorIcon style={{ fill: palette.error.main }} />
            <Typography sx={{ color: palette.error.main, fontSize: "1.4rem" }}>
              {errorMessage}
            </Typography>
          </ErrorContainer>
        )}
      </Stack>

      <Box
        component="img"
        src={warehouseImage}
        alt="autostore warehouse"
        width="100%"
        height="100%"
        sx={{ objectFit: "cover" }}
      />
    </Box>
  );
}

export default OrganizationLogin;
