import { useLoading } from "@agney/react-loading";
import ThreeDots from "@agney/react-loading/src/svg-loaders/three-dots.svg?react";
import { Suggestion20Px } from "@locaisolutions/icons";
import { VisibilityOff } from "@mui/icons-material";
import {
  Box,
  ButtonBase,
  Stack,
  Typography,
  styled,
  useTheme
} from "@mui/material";

import { useTranslation } from "react-i18next";

import { PortOrientation } from "~/api/portOrientation/portOrientation";
import { useAppSelector } from "~/app/store";

import { selectThisWorkstation } from "~/redux/selectors/workstationsSelectors";

import { ActivityState } from "./ActivityState";
import { AutostoreBinBorder } from "./AutostoreBinBorder";
import { AutostoreBinNumber } from "./AutostoreBinNumber";
import { orientCompartments } from "./orientCompartments";

const AutostoreBinGridStyled = styled(Box)(({ theme: { spacing } }) => ({
  display: "grid",
  alignItems: "stretch",
  flexGrow: 1,
  gap: spacing(0.5),
  position: "relative"
}));

const AutostoreBinSkeletonStyled = styled(Stack)(
  ({ theme: { palette, spacing } }) => ({
    WebkitMask:
      "radial-gradient(circle at top left, transparent 0, transparent 15px, black 0px) top left, " +
      "radial-gradient(circle at top right, transparent 0, transparent 15px, black 0px) top right, " +
      "radial-gradient(circle at bottom right, transparent 0, transparent 15px, black 0px) bottom right, " +
      "radial-gradient(circle at bottom left, transparent 0, transparent 15px, black 0px) bottom left",
    WebkitMaskSize: "55% 55%",
    WebkitMaskRepeat: "no-repeat",
    backgroundColor: palette.darkGray.light,
    padding: `${spacing(0)} ${spacing(2)}`,
    gap: spacing(0.5),
    position: "relative",
    maxWidth: "400px",
    minWidth: "200px",
    maxHeight: "240px",
    minHeight: "120px",
    aspectRatio: "5 / 3",
    width: "100%", // fixes issue where center aligning with flex will shrink the bin
    boxSizing: "border-box"
  })
);

const AutostoreBinCompartmentStyled = styled(ButtonBase, {
  shouldForwardProp: (propName: string) =>
    ![
      "selected",
      "empty",
      "isBinFlaggedOrInventoryOnHold",
      "highlight"
    ].includes(propName)
})<{
  selected: boolean;
  empty: boolean;
  isBinFlaggedOrInventoryOnHold: boolean;
  highlight: boolean;
}>(({
  theme: { palette, spacing },
  selected,
  empty,
  isBinFlaggedOrInventoryOnHold,
  highlight
}) => {
  let backgroundImage = "";
  if (!empty)
    backgroundImage =
      "linear-gradient(135deg, #ffffff 33.33%, #d1d1d1 33.33%, #d1d1d1 50%, #ffffff 50%, #ffffff 83.33%, #d1d1d1 83.33%, #d1d1d1 100%)";
  if (!empty && selected)
    backgroundImage = `linear-gradient(135deg, ${palette.success.main} 33.33%, #d1d1d1 33.33%, #d1d1d1 50%, ${palette.success.main} 50%, ${palette.success.main} 83.33%, #d1d1d1 83.33%, #d1d1d1 100%)`;

  const selectedColor = isBinFlaggedOrInventoryOnHold
    ? palette.warning.main
    : palette.primary.light;
  const backgroundColor =
    selected || isBinFlaggedOrInventoryOnHold
      ? selectedColor
      : palette.darkGray.main;

  return {
    display: "flex",
    flexDirection: "column",
    backgroundColor,
    backgroundImage,
    backgroundSize: `${spacing(2)} ${spacing(2)}`,
    border: highlight && selected ? `3px solid ${palette.primary.main}` : ""
  };
});

export const AutostoreBin = (props: {
  state: ActivityState;
  pickQuantity: number | string;
  pickCompartment: number | null;
  numberOfRows: number;
  numberOfColumns: number;
  binId?: number;
  hideBinId?: boolean;
  /** A number 0 based representing one of the bin comparments */
  selectedCompartment?: number;
  /** Numbers 1 based representing the bin compartments */
  emptyCompartments?: number[];
  showPickQtyWhileBinLoaderIsActive?: boolean;
  isCycleCountBlind?: boolean;
  setSelectedCompartmentCallback?: (compartment: number) => void;
  onBinClick?: (compartmentNumber?: number) => void;
  /** ONLY allow compartment highlighting on specific compartment click with inventory. */
  allowSelectingCompartmentsWithInventoryOnly?: boolean;
  activeTaskGroupCompartments?: number[];
  /** Allow compartment highlighting on overall bin click. */
  highlightActiveCompartment?: boolean;
  /** Numbers 1 based representing the bin compartments */
  compartmentNumberWithStatuses?: {
    autostoreCompartmentNumber: number | undefined;
    isAutostoreBinCompartmentEmpty: boolean | undefined;
  }[];
  /** Will display all quantities in compartments if provided */
  compartmentNumberWithQuantities?: {
    autostoreCompartmentNumber: number | undefined;
    quantity: number | undefined;
  }[];
  isSelectedCompartmentInventoryOnHold?: boolean;
  isBinFlagged?: boolean;
  moveTextCompartment?: number;
}) => {
  const { t } = useTranslation();
  const { palette } = useTheme();
  const { containerProps, indicatorEl } = useLoading({
    loading: true,
    indicator: <ThreeDots color={palette.darkGray.light} width="150" />
  });

  const {
    state,
    pickQuantity,
    pickCompartment,
    numberOfRows,
    numberOfColumns,
    binId: binNumber,
    hideBinId,
    selectedCompartment,
    emptyCompartments,
    showPickQtyWhileBinLoaderIsActive,
    isCycleCountBlind,
    setSelectedCompartmentCallback,
    onBinClick,
    allowSelectingCompartmentsWithInventoryOnly = false,
    activeTaskGroupCompartments,
    highlightActiveCompartment,
    compartmentNumberWithStatuses,
    isSelectedCompartmentInventoryOnHold,
    isBinFlagged,
    compartmentNumberWithQuantities,
    moveTextCompartment
  } = props;

  const workstation = useAppSelector(selectThisWorkstation);

  const orientedCompartments = orientCompartments(
    Array.from({ length: (numberOfRows ?? 1) * (numberOfColumns ?? 1) }).map(
      (_, c) => c + 1
    ),
    numberOfColumns,
    workstation?.orientation ?? PortOrientation.SOUTH
  );
  const pickQuantityCharacterLength = pickQuantity.toString().length;
  const fontSize =
    pickQuantityCharacterLength === 1 ? 7 : 9 - pickQuantityCharacterLength;
  const compartmentFontSize =
    state === "Waiting for Bin"
      ? `${fontSize}rem`
      : `${fontSize / numberOfColumns}rem`;

  const handleBinClick = (compartment: number) => {
    if (onBinClick && !allowSelectingCompartmentsWithInventoryOnly) {
      onBinClick(compartment);
    } else if (
      // If 'allowSelectingCompartmentsWithInventoryOnly' is true, only
      // allow selecting a compartment if has inventory
      onBinClick &&
      !emptyCompartments?.includes(compartment + 1)
    ) {
      onBinClick(compartment);
    }
    return setSelectedCompartmentCallback &&
      emptyCompartments?.includes(compartment + 1)
      ? setSelectedCompartmentCallback(compartment)
      : null;
  };

  const binLabel = ["Bin Opened"].includes(state)
    ? `bin-${binNumber}`
    : `no-bin`;

  const pickingQuantityEl = (qty: string | number) =>
    qty && ["Waiting for Bin", "Bin Opened"].includes(state) ? (
      <Typography
        variant="h1"
        fontWeight={600}
        fontSize={compartmentFontSize}
        lineHeight={1}
        color={
          state === "Waiting for Bin"
            ? palette.darkGray.light
            : palette.darkGray.dark
        }
      >
        {qty}
      </Typography>
    ) : (
      <></>
    );

  const moveTextEl = () => (
    <Stack direction="row">
      <Suggestion20Px
        fill={palette.primary.main}
        style={{ height: "100%", width: "40px", marginLeft: "16px" }}
      />
      <Typography lineHeight={1} mr={1} maxWidth="100px">
        {t("move inventory here")}
      </Typography>
    </Stack>
  );

  const isSelected = (compartmentNumber: number) =>
    activeTaskGroupCompartments?.includes(compartmentNumber) ||
    (selectedCompartment !== undefined &&
      compartmentNumber === selectedCompartment) ||
    (pickCompartment !== null && compartmentNumber === pickCompartment);

  return (
    <AutostoreBinSkeletonStyled
      sx={{ opacity: state === "Inactive" ? 0.35 : 1 }}
      role="figure"
      aria-label={binLabel}
    >
      <AutostoreBinBorder />
      {state === "Waiting for Bin" ? (
        <Stack
          flexGrow={1}
          justifyContent="center"
          alignItems="center"
          position="relative"
          bgcolor={palette.background.default}
          // TODO: Remove test id here
          data-testid={"loader"}
          {...containerProps}
        >
          {indicatorEl}
          {showPickQtyWhileBinLoaderIsActive && pickingQuantityEl(pickQuantity)}
          {!hideBinId && !!binNumber && (
            <AutostoreBinNumber binNumber={binNumber} />
          )}
        </Stack>
      ) : (
        <AutostoreBinGridStyled
          gridTemplateColumns={`repeat(${numberOfColumns ?? 1}, 1fr)`}
          gridTemplateRows={`repeat(${numberOfRows ?? 1}, 1fr)`}
        >
          {orientedCompartments.map((c) => (
            // TODO: lots of complexity here due to the number of available parameters
            <AutostoreBinCompartmentStyled
              key={c}
              onClick={() => handleBinClick(c - 1)}
              selected={isSelected(c - 1)}
              highlight={!!highlightActiveCompartment}
              empty={
                (emptyCompartments?.includes(c) ?? true) ||
                (compartmentNumberWithStatuses?.some(
                  (cnws) =>
                    cnws.autostoreCompartmentNumber === c &&
                    cnws.isAutostoreBinCompartmentEmpty
                ) ??
                  true)
              }
              isBinFlaggedOrInventoryOnHold={
                !!(
                  isBinFlagged ||
                  (isSelectedCompartmentInventoryOnHold && isSelected(c - 1))
                )
              }
              aria-selected={isSelected(c - 1)}
              aria-label={
                pickCompartment
                  ? `pickCompartment-${c - 1}`
                  : `compartment-${c - 1}`
              }
            >
              {isSelectedCompartmentInventoryOnHold && isSelected(c - 1) && (
                <Typography>{t("inventory hold")}</Typography>
              )}
              {((selectedCompartment !== undefined &&
                c - 1 === selectedCompartment) ||
                (pickCompartment !== null && c - 1 === pickCompartment)) &&
                (isCycleCountBlind ? (
                  <VisibilityOff fontSize="large" />
                ) : (
                  pickingQuantityEl(pickQuantity)
                ))}
              {compartmentNumberWithQuantities &&
                pickingQuantityEl(
                  compartmentNumberWithQuantities.find(
                    (compartment) =>
                      compartment.autostoreCompartmentNumber === c
                  )?.quantity ?? ""
                )}
              {moveTextCompartment === c && moveTextEl()}
            </AutostoreBinCompartmentStyled>
          ))}
          {!hideBinId && !!binNumber && (
            <AutostoreBinNumber binNumber={binNumber} />
          )}
        </AutostoreBinGridStyled>
      )}
      <AutostoreBinBorder />
    </AutostoreBinSkeletonStyled>
  );
};
