import { LDClient } from "launchdarkly-react-client-sdk";
import { useEffect } from "react";

import { setFlagsReadyFcId, setFlagsReadyClientId } from "~/app/app.slice";
import { useAppDispatch } from "~/app/store";

export function useSetLdFcId(args: {
  clientId: string | null;
  fcId: string | undefined;
  ldClient: LDClient | undefined;
}) {
  const dispatch = useAppDispatch();

  const { clientId, ldClient, fcId } = args;

  useEffect(() => {
    if (!clientId || !fcId || !ldClient) return;

    void (async () => {
      try {
        await ldClient.waitForInitialization(5);
        await ldClient.identify({
          kind: "multi",
          client: { kind: "client", key: clientId },
          "fulfillment-center": { kind: "fulfillment-center", key: fcId }
        });
        dispatch(setFlagsReadyFcId(true));
        dispatch(setFlagsReadyClientId(true));
      } catch (err) {
        console.error("FC identify error:", err);
      }
    })();
  }, [clientId, fcId, ldClient, dispatch]);
}
