import { ternaryIff } from "~/lib/helpers";
import { deepClone } from "~/lib/shared";
import {
  BatchDto,
  BinDto,
  PickDto,
  SignalRPickDto,
  WeightAdjustmentDto
} from "~/types/api";

import { BatchStatus } from "./batch.type";

export type LineItemStatus = "Fulfilled" | "Unfulfilled" | "OutOfStock";

/**
 * The model for a pick item
 * */

export type PickModel = {
  brandName: string;
  imageFilename: string;
  key: string;
  pickDto: PickDto | SignalRPickDto;
  pickId: string;
  name: string;
  quantity: {
    units: string;
    value: number;
  };
  sku: string;
  status: LineItemStatus;
  unitIsApproximate: boolean;
  upc: string;
  allUpcs: string[];
  variantId: Guid;
  pickBin?: BinDto;
  exceptionType: "OutOfStock" | null;
  weight?: number;
  assignedToteId?: Guid;
};

/**
 * The root viewmodel containing all data used by the batch pick workflow.
 */
export type BatchViewModel = {
  picks: PickModel[];
  orderCount: number;
  batchId: Guid;
  batchType: "Warehouse" | "Autostore" | "Store" | "Bulk";
  batchName: string;
  status: null | BatchStatus;
  cartNumber?: string | null;
};

export type BatchType = "Warehouse" | "Autostore" | "Store" | "Bulk";

/** Returns a formatted location of the bin */
function locationName(pickModel: PickModel): string {
  const { pickBin } = pickModel;
  const bay = pickBin?.shelfBay || pickBin?.palletBay;
  return pickBin && bay && pickBin.aisle && pickBin.shelf
    ? `${pickBin.aisle} ${bay} ${pickBin.shelf} ${pickBin.position ?? ""}`
    : "N/A";
}

function sortByLocation(a: PickModel, b: PickModel): number {
  const nameA = locationName(a);
  const nameB = locationName(b);
  return nameA.localeCompare(nameB);
}

export function createPickModel(pickDto: PickDto | SignalRPickDto): PickModel {
  const totalQuantity = pickDto.quantity.value;

  const key = pickDto.pickId;

  const pickBin = pickDto.pickBin || null;
  let lineItemStatus: LineItemStatus = "Unfulfilled";
  if (pickDto.status === "Completed") {
    if (pickDto.fulfilled) {
      lineItemStatus = "Fulfilled";
    }
    if (pickDto.exception && pickDto.exception.type === "OutOfStock") {
      lineItemStatus = "OutOfStock";
    }
  }
  if (pickDto.canceledReason === "OutOfStock") lineItemStatus = "OutOfStock";
  const { imageFilename } = pickDto;
  const groupExceptionType =
    pickDto.exception?.type === "OutOfStock"
      ? pickDto.exception.type
      : ternaryIff(pickDto.canceledReason === "OutOfStock", "OutOfStock", null);

  return {
    brandName: pickDto.brandName,
    exceptionType: groupExceptionType,
    imageFilename,
    key,
    pickDto,
    pickId: pickDto.pickId,
    name: pickDto.name,
    quantity: {
      units: pickDto.quantity.units,
      value: totalQuantity
    },
    sku: pickDto.sku,
    status: lineItemStatus,
    unitIsApproximate: pickDto.unitIsApproximate,
    upc: pickDto.upc,
    allUpcs: pickDto.allUpcs,
    variantId: pickDto.variantId,
    pickBin: pickBin || undefined,
    assignedToteId: pickDto.assignedToteId
  };
}

/** Build pick models */
function buildPickModels(pickDtos: PickDto[]): PickModel[] {
  return pickDtos
    .map((p) => createPickModel(p))
    .sort((a, b) => a.pickId.localeCompare(b.pickId))
    .sort(sortByLocation);
}

/** Create a pick item viewmodel from a list of orders. */
export function createBatchViewModel(
  batchDto: BatchDto,
  cartNumber?: string | null
): BatchViewModel {
  const { picks, batchId, batchName, batchType, status } = batchDto;
  const typedBatchType = batchType as BatchType;

  if (batchDto.picks.length === 0) {
    return {
      batchId,
      batchName,
      batchType: typedBatchType,
      picks: [],
      orderCount: 0,
      status: null,
      cartNumber
    };
  }

  const pickDtos: PickDto[] = picks.slice();
  const pickModels = buildPickModels(pickDtos);
  const orderCount = new Set(pickDtos.map((pick) => pick.orderId)).size;

  return {
    picks: pickModels,
    orderCount,
    batchId,
    batchName,
    batchType: typedBatchType,
    status: status as BatchStatus,
    cartNumber
  };
}

/** True if the picking of line items is complete, false otherwise. */
export function isPickingComplete(
  batchViewModel: BatchViewModel | undefined | null
): boolean {
  if (!batchViewModel || batchViewModel.orderCount === 0) {
    return false;
  }
  return batchViewModel.picks.every((pli) => pli.status !== "Unfulfilled");
}

/** Returns the count of fulfilled (including out of stock) line items. */
export function getFulfilledCount(items: PickModel[]): number {
  return items.filter((pickModel) => pickModel.status !== "Unfulfilled").length;
}

/** Returns a flattened list of pick items (across aisles). */
export function getAllPickModels(batchViewModel: BatchViewModel): PickModel[] {
  return batchViewModel.picks;
}

/** Looks up a pick model on a batch viewmodel that matches the location and key */
export function getMatchingPickModel(
  batchViewModel: BatchViewModel,
  pickModel: PickModel
): PickModel {
  const match = batchViewModel.picks.find((p) => p.key === pickModel.key);
  if (!match) {
    throw new Error(`Could not find a pick matching ${pickModel.key}`);
  }
  return match;
}

/** Fulfills a pick item, and all line items belonging to it. */
export function fulfill(
  batchViewModel: BatchViewModel,
  pickModel: PickModel,
  isFulfill: boolean
): BatchViewModel {
  // deep clone the pick item
  const fulfilledPickModel = deepClone(pickModel);
  // deep clone the pick item viewmodel
  const result = deepClone(batchViewModel);
  const status = isFulfill ? "Fulfilled" : "Unfulfilled";
  const pickDtoStatus = isFulfill ? "Completed" : "Scheduled";
  const putToteId = isFulfill
    ? fulfilledPickModel.pickDto.assignedToteId
    : undefined;

  fulfilledPickModel.status = status;
  if (fulfilledPickModel.exceptionType === "OutOfStock") {
    fulfilledPickModel.exceptionType = null;
  }

  fulfilledPickModel.pickDto.status = pickDtoStatus;
  fulfilledPickModel.pickDto.putToteId = putToteId;
  if (fulfilledPickModel.pickDto.exception?.type === "OutOfStock") {
    fulfilledPickModel.pickDto.exception = undefined;
  }
  // swap the old pick item with the updated pick item
  const pickModelIndex = result.picks.findIndex((p) => p.key === pickModel.key);
  result.picks[pickModelIndex] = fulfilledPickModel;
  return result;
}

/** Reverts a pick item */
export function revert(
  batchViewModel: BatchViewModel,
  pickModel: PickModel
): BatchViewModel {
  // deep clone the pick item
  const revertedPickModel = deepClone(pickModel);
  // deep clone the pick item viewmodel
  const result = deepClone(batchViewModel);

  revertedPickModel.status = "Unfulfilled";
  revertedPickModel.exceptionType = null;
  revertedPickModel.pickDto.exception = undefined;
  revertedPickModel.pickDto.status = "Scheduled";
  revertedPickModel.pickDto.fulfilled = false;
  revertedPickModel.pickDto.scannedUpcs = [];
  revertedPickModel.pickDto.putToteId = undefined;

  // swap the old pick item with the updated pick item
  const pickModelIndex = result.picks.findIndex((p) => p.key === pickModel.key);
  result.picks[pickModelIndex] = revertedPickModel;
  return result;
}

/** Out of stocks a pick item, and all the line items belonging to it. */
export function outOfStock(
  batchViewModel: BatchViewModel,
  pickModel: PickModel
): BatchViewModel {
  // deep clone the pick item
  const outOfStockPickModel = deepClone(pickModel);
  // deep clone the pick item viewmodel
  const result = deepClone(batchViewModel);
  outOfStockPickModel.status = "OutOfStock";
  outOfStockPickModel.pickDto.exception = {
    type: "OutOfStock",
    weightAdjustments: []
  };
  outOfStockPickModel.pickDto.status = "Completed";

  outOfStockPickModel.exceptionType = "OutOfStock";

  // swap the old pick item with the updated pick item
  const pickModelIndex = result.picks.findIndex((p) => p.key === pickModel.key);
  result.picks[pickModelIndex] = outOfStockPickModel;
  return result;
}

/** Adjusts the weight of a line item. */
export function adjustWeight(
  batchViewModel: BatchViewModel,
  pickId: Guid,
  weightAdjustment: WeightAdjustmentDto
): BatchViewModel {
  // deep clone the pick item viewmodel
  const result = deepClone(batchViewModel);

  const pickModel = getAllPickModels(result).find((p) => p.pickId === pickId);

  if (!pickModel) {
    throw new Error(`Could not find pick ${pickId}`);
  }
  const pickModelToAdjust = getMatchingPickModel(result, pickModel);

  const pick = pickModelToAdjust.pickDto;
  if (!pick) {
    throw new Error(`Could not find pick ${pickId}`);
  }

  const weightAdjustments = pick.exception?.weightAdjustments || [];
  weightAdjustments.push(weightAdjustment);

  const exceptionType = "WeightAdjustment";
  pick.exception = {
    type: exceptionType,
    weightAdjustments
  };

  return result;
}

/** Splits a line item, creating a new PickItemModel containing the newly split item. */
export function split(
  batchViewModel: BatchViewModel,
  pickModel: PickModel,
  remainingPick: PickDto,
  splitPick: PickDto
): BatchViewModel {
  // deep clone the pick item viewmodel
  const result = deepClone(batchViewModel);
  const pickModelToSplit = getMatchingPickModel(result, pickModel);

  pickModelToSplit.pickDto = remainingPick;

  const newPick = {
    ...pickModel,
    pickDto: splitPick,
    status: "Unfulfilled",
    pickId: splitPick.pickId,
    key: splitPick.pickId,
    quantity: splitPick.quantity
  } as PickModel;

  const indexOfOriginalItem = result.picks.findIndex(
    (item) => item.key === pickModel.key
  );
  result.picks.splice(indexOfOriginalItem + 1, 0, newPick);

  pickModelToSplit.quantity.value -= splitPick.quantity.value;

  return result;
}

/**
 * Updates the pick and pick model of a batch directly from a PickDto.
 * This is generally used to update the viewmodel from SignalR events.
 */
export function update(
  batchViewModel: BatchViewModel,
  pick: SignalRPickDto
): BatchViewModel {
  // deep clone the batch viewmodel
  const result = deepClone(batchViewModel);

  const newPickModel = createPickModel(pick);

  // swap the old pick item with the updated pick item
  const pickModelIndex = result.picks.findIndex(
    (p) => p.key === newPickModel.key
  );
  result.picks[pickModelIndex] = newPickModel;
  return result;
}
