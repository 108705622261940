import { DrawerProps, Theme } from "@mui/material";
import { Box, BoxProps, SystemStyleObject } from "@mui/system";
import { mergeSx } from "merge-sx";
import { ReactElement, ReactNode, cloneElement } from "react";

type Props = {
  AppBar: ReactNode;
  Drawer?: ReactElement<DrawerProps>;
} & BoxProps;

const appBarHeight = "48px";

/**
 * CSS grid container that renders major layout components.
 * @param AppBar The global bar across the top of the page that is always rendered at the top of
 *    the shell.
 * @param Drawer Typically a MUI Drawer that renders outside the layout of the app.
 *    The top, bottom and height of the drawer will be overridden to render under the AppBar.
 * @param children The main content of the page.  The elements passed in are rendered within a <main> tag.
 * @param rest Overrides the Shell's default
 * @constructor
 */
export function Shell({ AppBar, Drawer, children, ...rest }: Props) {
  // override the drawer's dimensions according to the shell's grid layout
  // in order to render the drawer under the AppBar
  const originalSx = Drawer?.props.sx;
  const originalPaperProps =
    (originalSx &&
      Object.hasOwn(originalSx, "& .MuiDrawer-paper") &&
      (originalSx as { [key: string]: SystemStyleObject<Theme> })[
        "& .MuiDrawer-paper"
      ]) ||
    {};
  const drawerProps = {
    sx: mergeSx(
      {
        "& .MuiDrawer-paper": {
          top: appBarHeight,
          bottom: `-${appBarHeight}`,
          height: `calc(100% - ${appBarHeight})`,
          ...originalPaperProps
        }
      },
      originalSx
    )
  };

  const drawer = Drawer && drawerProps && cloneElement(Drawer, drawerProps);

  return (
    <Box
      display="grid"
      height="100vh"
      overflow="hidden"
      /* AppBar height followed by remaining content */
      gridTemplateRows={`${appBarHeight} 1fr`}
      gridTemplateColumns="1fr"
      {...rest}
    >
      {/* AppBar */}
      {AppBar}

      {/* Drawer */}
      {drawer}

      {/* Children */}
      <Box component="main" gridRow="2" sx={{ overflowY: "auto" }}>
        {children}
      </Box>
    </Box>
  );
}
