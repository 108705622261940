import {
  Autocomplete,
  Button,
  Paper,
  Stack,
  TextField,
  Typography
} from "@mui/material";
import { useToast } from "@qubit/autoparts";
import { QueryStatus, skipToken } from "@reduxjs/toolkit/query";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import { useAppSelector, useAppDispatch } from "~/app/store";

import { getMessageFromRtkError } from "~/lib/rtkErrorToMessage";
import { useGridV2Subscription } from "~/lib/signalr";
import {
  selectWorkstationAutostoreGridId,
  selectThisWorkstationInboundCleaningPortId
} from "~/redux/selectors/workstationsSelectors";
import { useGetBinsOutsideGridQuery } from "~/redux/warehouse/autostoreGrid.hooks";
import { useSimulateBinScanMutation } from "~/redux/warehouse/hardwareConductor.hooks";

import { warehouseApi } from "~/redux/warehouse/warehouseApi";

export function ConveyanceSimulator() {
  const gridId = useAppSelector(selectWorkstationAutostoreGridId);
  const inboundPortId = useAppSelector(
    selectThisWorkstationInboundCleaningPortId
  );
  const [binNumber, setBinNumber] = useState<string | null>(null);
  const [inputValue, setInputValue] = useState<string>("");
  const [simulateScan, { status: simulateBinReturnRequestStatus }] =
    useSimulateBinScanMutation();
  const { errorToast, successToast } = useToast();
  const { data } = useGetBinsOutsideGridQuery(
    gridId ? { autostoreGridId: gridId } : skipToken
  );
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const simulateBinReturn = async () => {
    if (!gridId) {
      errorToast("Unable to Simulate Bin Return", {
        description: "Grid ID is not defined for this workstation."
      });
      return;
    }
    if (!inboundPortId) {
      errorToast("Unable to Simulate Bin Return", {
        description: "Inbound port ID is not defined for this workstation."
      });
      return;
    }
    if (!binNumber) {
      errorToast("Unable to Simulate Bin Return", {
        description: "Invalid bin number."
      });
      return;
    }
    try {
      await simulateScan({
        gridId,
        portId: inboundPortId,
        binNumber: parseInt(binNumber)
      }).unwrap();
      successToast("Simulated Bin Return Scan Successfully", {
        description: `Scanned bin number ${binNumber}`
      });
    } catch (error) {
      errorToast(getMessageFromRtkError(error));
    }
  };

  const simulateBinScan = () => {
    if (!binNumber) {
      errorToast("Unable to Simulate Scan", {
        description: "Invalid bin number."
      });
      return;
    }
    window.simScan(binNumber);
  };

  useGridV2Subscription((event) => {
    if (event.event.gridId !== gridId) {
      return;
    }
    switch (event.case) {
      case "BinModeChange":
        if (
          event.event.binId === +(binNumber || -1) &&
          event.event.previousBinMode === "X"
        ) {
          setBinNumber(null);
          successToast("Bin Picked Up At Port", {
            description: `Bin number ${event.event.binId} is now inserted into the grid.`
          });
          dispatch(warehouseApi.util.invalidateTags(["bin maintenance"]));
        } else if (event.event.binMode === "X") {
          successToast("Bin Deposited At Cleaning Station", {
            description: `Bin number ${event.event.binId} dropped off for cleaning.`
          });
          dispatch(warehouseApi.util.invalidateTags(["bin maintenance"]));
        }
    }
  });

  return (
    <Paper sx={{ p: 2, mt: 2 }}>
      <Stack spacing={2}>
        <Typography variant="h6" textAlign="center">
          {t("conveyance simulation")}
        </Typography>
        <Autocomplete
          options={data?.map((binId) => binId.toString()) || []}
          value={binNumber}
          onChange={(_e, newValue) => setBinNumber(newValue)}
          inputValue={inputValue}
          onInputChange={(_, newValue) => setInputValue(newValue)}
          renderInput={(params) => (
            <TextField {...params} variant="outlined" label={t("bin number")} />
          )}
        />
        <Stack direction="row" justifyContent="space-between">
          <Button variant="subtle" onClick={simulateBinScan}>
            {t("scan bin")}
          </Button>
          <Button
            variant="subtle"
            onClick={simulateBinReturn}
            disabled={simulateBinReturnRequestStatus === QueryStatus.pending}
          >
            {t("simulate bin return")}
          </Button>
        </Stack>
      </Stack>
    </Paper>
  );
}
