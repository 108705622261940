import { Box, Grid, Typography } from "@mui/material";
import {
  ScanningIndicator,
  useScanIndicator,
  useToast,
  ProductCard
} from "@qubit/autoparts";
import { skipToken } from "@reduxjs/toolkit/query";
import * as Sentry from "@sentry/react";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect, ConnectedProps } from "react-redux";
import { useNavigate } from "react-router-dom";

import { ScanningVerification } from "~/api/scanningVerifyMethod";
import { PickedToteWithCaller } from "~/api/toteTypes/pickedTote";
import { useAppDispatch, useAppSelector } from "~/app/store";
import envConstants from "~/config/envConstants";
import useFlag from "~/config/flags";
import { StartPickingModal } from "~/features/startPickingModal/StartPickingModal";
import {
  closeStartPickingModal,
  openStartPickingModal
} from "~/features/startPickingModal/startPicking.slice";
import { useBinStatusCheck } from "~/hooks/useBinStatusCheck";
import { debounce } from "~/hooks/useDebounce";
import { useDevCheats } from "~/hooks/useDevCheats";
import { useInactivityResetTimer } from "~/hooks/useInactivityResetTimer";
import { useNavbar, ViewNameTranslation } from "~/hooks/useNavbar";
import { useShouldListenToGridEvents } from "~/hooks/useShouldListenToGridEvents";
import { useBarcodeScanner, useKeyDownHandler } from "~/lib/barCodeScan";
import { getPortSide } from "~/lib/getPortSide";
import {
  ternaryIff,
  getCompartmentId,
  matchExactUpcsOrSkus,
  displayPickData,
  isSiteUsingShipments,
  getBarcodeValue,
  matchesUpc
} from "~/lib/helpers";
import { parsePortSide } from "~/lib/parsePortSide";
import { getMessageFromRtkError } from "~/lib/rtkErrorToMessage";
import {
  useGridV2Subscription,
  usePickSubscription,
  useToteSubscription
} from "~/lib/signalr";
import usePromiseInterval from "~/lib/usePromiseIntervalEffect";
import {
  fetchNextPickBin,
  outOfStockPickAutostore,
  setPreviousAutostoreBin,
  updatePickingState,
  clearPickingState,
  setCartNumberConfirmed,
  updatePickingStateFromEvent,
  fetchBinLogPublisherState,
  fetchPortStatus,
  setNextPickingBinLoading,
  setIsPickQuantityConfirmed,
  showPickToLight,
  setBinIsPresent,
  setLeftOrRightPortActive,
  startPickingOnWorkstation,
  setPickedTote,
  setTotePickingStarted,
  restartNextPickingBinFailureMessage,
  restartSplitAutostore,
  noBatchesLeftMessage
} from "~/redux/actions";
import {
  fetchBatchWithCartNumber,
  restartTotesByBatch
} from "~/redux/actions/batch";
import { fetchToteById } from "~/redux/actions/home";
import {
  setSelectedInventoryId,
  clearSelectedInventoryId
} from "~/redux/actions/inventory";
import { clearUserMessage } from "~/redux/actions/site";
import { usePartiallyCompleteMutation } from "~/redux/public/picks.hooks";
import { StoreState } from "~/redux/reducers";
import { selectUsersClientId } from "~/redux/selectors/authSelectors";
import { selectCurrentPick } from "~/redux/selectors/autostoreSelectors";
import {
  selectAbleQtyToPick,
  selectOutOfStockModalStatus,
  selectOutOfStockReasonCode
} from "~/redux/selectors/outOfStockSelectors";
import {
  selectPickingScannedBarcode,
  selectStartPickingModalIsOpen
} from "~/redux/selectors/pickingSelectors";
import { selectClientConfig } from "~/redux/selectors/siteSelectors";
import { selectIsRecurringScheduleFc } from "~/redux/selectors/storeSelectors";
import { useGetWaveProgressQuery } from "~/redux/warehouse/batches.hooks";
import { useGetInventoryByIdQuery } from "~/redux/warehouse/inventory.hooks";
import { useLazyGetOrdersQuery } from "~/redux/warehouse/orders.hooks";
import {
  useCompletePickMutation,
  useLazyGetPickQuery,
  useVerifyPickMutation
} from "~/redux/warehouse/pick.hooks";
import { useGetRecurringSchedulesQuery } from "~/redux/warehouse/recurringSchedule.hooks";
import { useScanLabelMutation } from "~/redux/warehouse/totes.hooks";
import { warehouseApi } from "~/redux/warehouse/warehouseApi";
import { useGetWorkstationsQuery } from "~/redux/warehouse/workstation.hooks";
import {
  AutostoreEvent,
  AutostorePickingState,
  SignalRPickEventDto,
  ToteEventDto
} from "~/types/api";

import AddToteModal from "./AddToteModal";
import { AutostorePickingTotes } from "./AutostorePickingTotes";
import { BarcodeInput } from "./BarcodeInput";
import PickingBins from "./PickingBins";
import RestockPickModal from "./RestockPickModal";
import TopActionButtons from "./TopActionButtons";
import { closeAddToteModal } from "./addToteModal.slice";
import ScanAndApplyLabelConfirm from "./applyLabelDialog/ScanAndApplyLabel";
import {
  incrementScanAndApplyModalActiveStep,
  resetScanAndApplyModalActiveStep
} from "./applyLabelDialog/scanAndApplyLabel.slice";
import { TotesPrepModal } from "./assignTotesDialog/TotesPrepModal";
import {
  setCurrentPickQuantity,
  setPickCompartment
} from "./autostorePicking.slice";
import { CartNumberConfirmationModal } from "./cartNumberConfirmationModal/CartNumberConfirmationModal";
import { ConfirmPickQuantityModal } from "./confirmPickQuantityModal/ConfirmPickQuantityModal";
import { setPickedQuantityModalStatus } from "./confirmPickQuantityModal/confirmPickQuantityModal.slice";
import { useAddedTotes } from "./hooks/useAddedTotes";
import usePickingFunctionalities from "./hooks/usePickingFunctionalities";
import AutostoreOutOfStockDialog from "./outOfStockDialog/AutostoreOutOfStockDialog";
import {
  setAbleToPickQty,
  setOutOfStockDialogStatus
} from "./outOfStockDialog/outOfStockDialog.slice";
import { closeHoldBinModal } from "./problemSolveModal.slice";
import { openRestockPickModal } from "./restockPickModal.slice";
import {
  resetScannedBarcode,
  setScannedBarcode
} from "./slices/pickingScannedBarcode.slice";

export const trackedPageName = "Picking";

const mapStateToProps = (state: StoreState) => ({
  selectedAutostoreGridId: state.workstations.siteWorkstation?.autostoreGridId,
  sitePortId: state.workstations.sitePortId,
  portState: state.autostore.portState,
  signalr: state.autostore.signalr,
  batchWithCartNumber: state.batch.batchWithCartNumber,
  pickingConfigurations:
    state.store.usersFulfillmentCenter?.pickingConfigurations || null,
  pickingState: state.autostore.pickingState,
  pickingStateLoading: state.autostore.pickingStateLoading,
  cartNumberConfirmed: state.autostore.cartNumberConfirmed,
  fulfillmentCenter: state.store.usersFulfillmentCenter,
  userMessages: state.site.userMessages,
  batchName: state.autostore.pickingState?.batchName,
  taskGroupId: state.autostore.pickingState?.taskGroupId,
  automatedOperationsEnabled: state.site.automatedOperationsEnabled,
  isPickQuantityConfirmed: state.autostore.isPickQuantityConfirmed,
  binIsPresent: state.autostore.binIsPresent.status,
  binIsPresentBinId: state.autostore.binIsPresent.binId,
  nextPickingStateLoading: state.autostore.nextPickingStateLoading,
  leftOrRightPortActive: state.autostore.leftOrRightPortActive,
  siteAllPortIds: state.workstations.siteAllPortIds,
  siteWorkstation: state.workstations.siteWorkstation,
  pickedTote: state.autostore.pickedTote,
  pickingStartedToteId: state.autostore.pickingStartedToteId,
  allPicksCompletedErrorMessage: state.autostore.allPicksCompletedErrorMessage,
  showTwoBinComponents: state.autostore.showTwoBinComponents,
  splitPickResponse: state.autostore.splitAutostore,
  batchTotes: state.batch.batchTotes,
  displayToteConfirmationModalForTote:
    state.autostore.displayToteConfirmationModalForTote
});

const connector = connect(mapStateToProps, {
  fetchNextPickBin,
  fetchPortStatus,
  outOfStockPickAutostore,
  setPreviousAutostoreBin,
  setSelectedInventoryId,
  clearSelectedInventoryId,
  fetchBatchWithCartNumber,
  updatePickingState,
  setCartNumberConfirmed,
  updatePickingStateFromEvent,
  clearPickingState,
  fetchBinLogPublisherState,
  showPickToLight,
  setIsPickQuantityConfirmed,
  setBinIsPresent,
  setNextPickingBinLoading,
  setLeftOrRightPortActive,
  startPickingOnWorkstation,
  setPickedTote,
  setTotePickingStarted,
  restartNextPickingBinFailureMessage,
  clearUserMessage,
  fetchToteById,
  restartSplitAutostore,
  restartTotesByBatch
});

type Props = ConnectedProps<typeof connector> & {
  viewTitle?: ViewNameTranslation;
};

export function AutostorePicking(props: Props) {
  // props
  const {
    selectedAutostoreGridId,
    sitePortId,
    portState,
    batchWithCartNumber,
    pickingConfigurations,
    cartNumberConfirmed,
    fulfillmentCenter,
    userMessages,
    isPickQuantityConfirmed,
    binIsPresent,
    nextPickingStateLoading,
    leftOrRightPortActive,
    siteAllPortIds,
    siteWorkstation,
    pickedTote,
    pickingStartedToteId,
    binIsPresentBinId,
    allPicksCompletedErrorMessage,
    viewTitle,
    showTwoBinComponents,
    splitPickResponse,
    batchTotes,
    displayToteConfirmationModalForTote,
    pickingState,
    pickingStateLoading,
    batchName,
    taskGroupId,
    updatePickingState
  } = props;

  const clientConfig = useAppSelector(selectClientConfig);

  const {
    ap_totesPlacement: totesPlacement,
    ap_prepTotesModalEnabled: prepTotesModalEnabled,
    ap_confirmCartNumberEnabled,
    ap_fusionPortScreenEnabled,
    ap_scanningIndicatorShown,
    ap_applyingLabelProcessEnabled,
    ap_showPickToLight,
    ap_showOrderOrProductCancellationModal
  } = clientConfig;

  // hooks
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { errorToast, successToast } = useToast();
  const dispatch = useAppDispatch();
  const { setInformationalMenuItems, setMenuItems } = useNavbar({
    isAutostorePicking: true,
    viewTitle
  });
  const [scanState, setScanState] = useScanIndicator();
  const binStatusCheck = useBinStatusCheck();
  const { originalTotes, addedTotes } = useAddedTotes();
  const shouldListenToGridEvents = useShouldListenToGridEvents();
  const { handleSlamProcess } = usePickingFunctionalities();
  const { restartInactivityTimer, clearInactivityTimer } =
    useInactivityResetTimer({});

  // selectors
  const clientId = useAppSelector(selectUsersClientId);
  const isRestockPickModalOpen = useAppSelector(
    (state) => state.restockPickModal.isOpen
  );
  const outOfStockModalStatus = useAppSelector(selectOutOfStockModalStatus);
  const outOfStockReasonCode = useAppSelector(selectOutOfStockReasonCode);
  const isAddToteModalOpen = useAppSelector(
    (state) => state.addToteModal.isOpen
  );
  const ableToPickQty = useAppSelector(selectAbleQtyToPick);
  const batchCompletedModalOpen = useAppSelector(selectStartPickingModalIsOpen);
  const scannedBarcode = useAppSelector(selectPickingScannedBarcode);
  const confirmPickQuantityModalIsShown = useAppSelector(
    (state) => state.confirmPickQuantityModalSlice.isShown
  );
  const problemSolveModalOpen = useAppSelector(
    (state) => state.problemSolveModal.isOpen
  );
  const isRecurringScheduleFc = useAppSelector(selectIsRecurringScheduleFc);
  const currentPick = useAppSelector(selectCurrentPick);

  // useState
  const [recentBatchInfo, setRecentBatchInfo] = useState<{
    batchId?: Guid;
    batchName?: string;
    temperatureZone?: string;
  } | null>(null);

  // for scanning individual products within a pick
  const [currentPickScannedCount, setCurrentPickScannedCount] =
    useState<number>(0);

  const [portPollingActive, setPortPollingActive] = useState<boolean>(false);
  useDevCheats({ isPortPolling: portPollingActive, showAutostoreStatus: true });

  const [binAtPortSeconds, setBinAtPortSeconds] = useState(0);

  const [isHandleMoveNextPickCalled, setIsHandleMoveNextPickCalled] =
    useState(false);

  // useRef
  const isHandleMoveNextPickCalledTimer = useRef(0);
  const isToteBoxClicked = useRef(false);
  const lastPickedTote = useRef<PickedToteWithCaller>({
    pickedTote: null,
    caller: null
  });
  const nextPickingBinCallerTimestamp = useRef<number | null>(null);

  const { areShipmentsEnabled } = useGetRecurringSchedulesQuery(undefined, {
    skip: !isRecurringScheduleFc,
    selectFromResult: (result) => ({
      areShipmentsEnabled: isSiteUsingShipments(result.data)
    })
  });

  const { data: _ } = useGetWorkstationsQuery(undefined, {
    refetchOnMountOrArgChange: true
  });

  const {
    refetch: refetchGetWaveProgress,
    isError: isWaveProgressBarError,
    error: waveProgressBarError
  } = useGetWaveProgressQuery(
    pickingState && areShipmentsEnabled
      ? { batchId: pickingState.batchId }
      : skipToken,
    {
      refetchOnMountOrArgChange: true
    }
  );

  const { data: inventory } = useGetInventoryByIdQuery(
    currentPick ? { inventoryId: currentPick.inventoryId } : skipToken,
    { refetchOnMountOrArgChange: true }
  );

  const [fetchPick] = useLazyGetPickQuery();
  const [fetchOrder] = useLazyGetOrdersQuery();
  const [verifyPick] = useVerifyPickMutation();
  const [scanLabel] = useScanLabelMutation();
  const [completePick] = useCompletePickMutation();
  const [partiallyComplete] = usePartiallyCompleteMutation();

  const isStartPickingOnWorkstationEndpointEnabled =
    useFlag().useStartPickingOnWorkstationEndpoint;
  const isServerSideNextPickingBinEnabled = useFlag().serverSidePickingBin;

  const currentPickUpcs = currentPick?.allUpcs;

  const totePositionsConfirmed = pickingState?.totes.reduce((acc, tote) => {
    const isConfirmed = [
      "Position Confirmed",
      "Picked",
      "Picking",
      "Canceled"
    ].includes(tote.status);
    return isConfirmed && acc;
  }, true);

  const shouldDisplayToteConfirmationModalForSpecificTote = () => {
    if (pickingState && displayToteConfirmationModalForTote) {
      const toteInCurrentBatch = pickingState.totes.find(
        (tote) => tote.toteId === displayToteConfirmationModalForTote.toteId
      );
      return !!toteInCurrentBatch;
    }

    return false;
  };

  const displayToteConfirmationModalForSpecificTote =
    shouldDisplayToteConfirmationModalForSpecificTote();
  const displayTotesPrepModal =
    prepTotesModalEnabled &&
    !batchCompletedModalOpen &&
    pickingState &&
    pickingState.totes.length &&
    (!totePositionsConfirmed || displayToteConfirmationModalForSpecificTote);

  // Property which indicate if Scan Label Modal should be displayed after all picks from tote are completed
  const isScanLabelModalHidden = ap_applyingLabelProcessEnabled
    ? !pickedTote
    : true;

  const isScanAndApplyLabelModalShown =
    !isScanLabelModalHidden && !outOfStockModalStatus;

  const isPickQuantityConfirmationFlowEnabled =
    fulfillmentCenter?.pickQuantityConfirmationEnabled &&
    !!currentPick &&
    currentPick?.quantity.value > 1 &&
    ((prepTotesModalEnabled && totePositionsConfirmed) ||
      (ap_confirmCartNumberEnabled && cartNumberConfirmed));

  const isCartNumberConfirmationShown =
    ap_confirmCartNumberEnabled && !cartNumberConfirmed;

  const handleFetchNextPickBin = async () => {
    if (!pickingState) return;

    const autostoreBinId = currentPick?.pickBin?.autostoreBin?.autostoreBinId;

    const autostoreBinGuid = currentPick?.pickBin?.binId;

    let allPicksComplete;
    let nextBinWasSuccessful = false;

    // We need to call update picking state before fetching next picking bin also
    // Because we can end up in scenario where we for example
    // Have two orders and if we cancel currently non active order
    // And complete pick from active order, our picking state wouldn't be updated
    // So our tote progress bar wouldn't show proper color of canceled picks
    let updatedPickingState = null;
    try {
      updatedPickingState = await updatePickingState({
        ignoreErrorMessage: true
      });
    } catch {
      // do nothing
    }
    let newCurrentPick = currentPick;
    // change the current pick
    if (updatedPickingState && updatedPickingState.currentPicks.length) {
      // current pick comes from picking state and is assumed to be the first pick with the highest quantity in the current picks array
      newCurrentPick =
        updatedPickingState?.currentPicks.reduce(
          (max, pick) =>
            pick?.quantity?.value > max?.quantity?.value ? pick : max,
          pickingState.currentPicks[0]
        ) || null;
      // or fetch next pick bin, then
    } else {
      try {
        allPicksComplete = await props.fetchNextPickBin({
          portId: sitePortId || undefined
        });
        nextBinWasSuccessful = true;
      } catch {
        // do nothing
      }

      if (!nextBinWasSuccessful) return;

      if (allPicksComplete) {
        // When the last tote in the batch is completed, we have two paths for updating state related to the SLAM modal:
        // 1. Update state which will show the SLAM modal when the all picks are completed - This one is expected here
        // 2. Update state which will show the SLAM modal when the 'tote picked' event is received
        if (
          ap_applyingLabelProcessEnabled &&
          !lastPickedTote.current.caller &&
          lastPickedTote.current.pickedTote &&
          lastPickedTote.current.pickedTote.toteId
        ) {
          lastPickedTote.current.caller = "all picks completed";
          const pickedToteDto = await props.fetchToteById(
            lastPickedTote.current.pickedTote.toteId
          );
          await handleSlamProcess(
            lastPickedTote.current.pickedTote.toteId,
            lastPickedTote.current.pickedTote.orderId,
            pickedToteDto
          );
        }
        dispatch(openStartPickingModal());
        dispatch(setOutOfStockDialogStatus(false));
      } else {
        // is this repeated?  How would it effect the rest of this function by fetching again?
        await updatePickingState({});
      }
    }
    if (currentPick?.inventoryId)
      props.setSelectedInventoryId(currentPick.inventoryId);
    else props.clearSelectedInventoryId();

    props.setPreviousAutostoreBin({
      binId: autostoreBinId,
      binGuid: autostoreBinGuid,
      autostoreCompartmentNumber:
        newCurrentPick?.pickBin?.autostoreCompartmentNumber || 1,
      inventoryId: newCurrentPick?.inventoryId
    });
  };

  const resetAfterPick = () => {
    dispatch(setCurrentPickQuantity(0));
    setCurrentPickScannedCount(0);
    if (fulfillmentCenter?.pickQuantityConfirmationEnabled) {
      props.setIsPickQuantityConfirmed(false);
      dispatch(resetScannedBarcode());
    }
    if (ap_fusionPortScreenEnabled) {
      props.setLeftOrRightPortActive("Unknown");
    }
    if (!shouldListenToGridEvents) {
      setPortPollingActive(true);
    }
  };

  const handleMoveNextPick = debounce(async (args: { reporter: string }) => {
    const { reporter } = args;

    if (
      (nextPickingStateLoading &&
        reporter.toLowerCase() !== "added tote click") ||
      batchCompletedModalOpen
    ) {
      return;
    }

    // Check the timestamp difference between the last two calls to this function
    // If the difference is less than a second, just ignore the second call
    const callerTimestamp = new Date().getTime();
    if (
      nextPickingBinCallerTimestamp.current &&
      Math.abs(callerTimestamp - nextPickingBinCallerTimestamp.current) < 1000
    ) {
      nextPickingBinCallerTimestamp.current = callerTimestamp;
      return;
    }
    nextPickingBinCallerTimestamp.current = callerTimestamp;

    if (
      !pickingState ||
      (pickingState && pickingState.currentPicks.length <= 1)
    ) {
      props.setBinIsPresent({
        status: false,
        reporter
      });
    }

    resetAfterPick();
    await handleFetchNextPickBin();
    props.setNextPickingBinLoading(false);
  }, 500);

  const resetStateAndRedirect = useCallback(
    (reporter: string, callback?: () => void) => {
      // If fulfillment center pickQuantityConfirmationEnabled property is enabled, we want to reset modal state
      if (
        fulfillmentCenter &&
        fulfillmentCenter.pickQuantityConfirmationEnabled
      ) {
        dispatch(
          setPickedQuantityModalStatus({
            isShown: false,
            pickedQuantity: null,
            selectedBinId: null
          })
        );
      }
      dispatch(
        setBinIsPresent({
          status: false,
          reporter
        })
      );

      if (ap_applyingLabelProcessEnabled) {
        if (pickedTote) {
          dispatch(setPickedTote(null));
        }
        if (pickingStartedToteId) {
          dispatch(setTotePickingStarted(null));
        }
        if (batchTotes) {
          dispatch(restartTotesByBatch());
        }
      }

      if (allPicksCompletedErrorMessage) {
        dispatch(restartNextPickingBinFailureMessage());
      }

      if (fulfillmentCenter?.pickQuantityConfirmationEnabled) {
        dispatch(setIsPickQuantityConfirmed(false));
        dispatch(resetScannedBarcode());
      }
      if (callback) {
        callback();
      }
    },
    [
      allPicksCompletedErrorMessage,
      ap_applyingLabelProcessEnabled,
      batchTotes,
      dispatch,
      fulfillmentCenter,
      pickedTote,
      pickingStartedToteId
    ]
  );

  const confirmPick = async (pickId: Guid) => {
    if (
      !pickingState ||
      !sitePortId ||
      !selectedAutostoreGridId ||
      !clientId ||
      !fulfillmentCenter
    )
      return;

    // find first current pick
    const firstCurrentPick = pickingState.currentPicks.find(
      (pick) => pick.pickId === pickId
    );
    if (!firstCurrentPick) return;

    // check if pick was already completed, if yes just move forward
    const fetchedPick = await fetchPick(pickId).unwrap();
    if (!fetchedPick) return;
    if (fetchedPick.status === "Completed") {
      // Debugging step to understand how often this happens that PTL is pressed but the FE doesn't know.
      Sentry.captureMessage(
        `Attempted to complete pick (${pickId}) but was found to be completed already.`
      );
      return;
    }
    isToteBoxClicked.current = true;

    if (ableToPickQty && selectedAutostoreGridId) {
      await partiallyComplete({
        pickId,
        clientId,
        fulfillmentCenterId: fulfillmentCenter.fulfillmentCenterId,
        requestBody: {
          autostoreGridId: selectedAutostoreGridId,
          holdReasonCode: outOfStockReasonCode,
          quantity: ableToPickQty,
          pickBinId: firstCurrentPick.pickBin?.binId || "",
          workstationId: siteWorkstation?.id
        }
      });
      dispatch(warehouseApi.util.invalidateTags(["focused pick"]));
      dispatch(
        warehouseApi.util.invalidateTags([
          { type: "tote", id: firstCurrentPick.assignedToteId }
        ])
      );

      // After a successful partial OOS, we should update picking state
      // In order to have updated information about tote progress bar
      await updatePickingState({});
    } else {
      try {
        await completePick({
          gridId: selectedAutostoreGridId,
          portId: sitePortId,
          scannedPick: {
            pickId: firstCurrentPick.pickId,
            scannedUpcs: []
          },
          fulfilled: true
        }).unwrap();
      } catch (err) {
        errorToast(getMessageFromRtkError(err));
        // make sure we skip below, but not ready for everything to be in this try yet
        // TODO: use RTK for the below
        throw err;
      }

      // When the last pick in the tote is completed, we have two paths for updating state related to the SLAM modal:
      // 1. Update state which will show the SLAM modal when the last pick is confirmed from the Qubit - This one is expected here
      // 2. Update state which will show the SLAM modal when the 'tote picked' event is received
      if (
        ap_applyingLabelProcessEnabled &&
        !lastPickedTote.current.caller &&
        lastPickedTote.current.pickedTote &&
        lastPickedTote.current.pickedTote.toteId
      ) {
        lastPickedTote.current.caller = "confirm pick";
        const pickedToteDto = await props.fetchToteById(
          lastPickedTote.current.pickedTote.toteId
        );
        await handleSlamProcess(
          lastPickedTote.current.pickedTote.toteId,
          lastPickedTote.current.pickedTote.orderId,
          pickedToteDto
        );
      }
    }
  };

  const handleToteClick = async (args: {
    toteId: Guid;
    pickId: Guid;
    forbidHandleMoveNextPickCall?: boolean;
    reporter?: string;
  }) => {
    const { pickId, forbidHandleMoveNextPickCall, reporter } = args;

    props.setNextPickingBinLoading(true);

    if (!isHandleMoveNextPickCalled) {
      setIsHandleMoveNextPickCalled(true);
    }
    try {
      await confirmPick(pickId);

      if (!forbidHandleMoveNextPickCall) {
        if (!isServerSideNextPickingBinEnabled)
          await handleMoveNextPick({ reporter: reporter || "tote click" });
      } else {
        props.setNextPickingBinLoading(false);
      }
    } catch {
      props.setNextPickingBinLoading(false);
    }
  };

  // initial page load
  // every 5 seconds if signalr is disconnected
  const fetchPortStatusAndUpdateOverallState = useCallback(
    async (source: string) => {
      try {
        const portStatus = await dispatch(
          fetchPortStatus({
            portId: sitePortId || undefined
          })
        );
        if (portStatus && portStatus.selectedTask && sitePortId) {
          const fetchedPickingState = await updatePickingState({
            validationTaskId: portStatus.selectedTask
          });
          if (fetchedPickingState?.isBinOpened) {
            // this can be set in redux after picking state is updated
            dispatch(
              setBinIsPresent({
                status: true,
                reporter: `${source} | fn a: pickingState`,
                binId:
                  fetchedPickingState.currentPicks[0]?.pickBin?.autostoreBin
                    ?.autostoreBinId
              })
            );
          } else {
            dispatch(
              setBinIsPresent({
                status: false,
                reporter: `${source} | fn a: pickingState`
              })
            );
          }
        } else {
          dispatch(
            setBinIsPresent({
              status: false,
              reporter: `${source} | fn a: no portState selectedTask`
            })
          );
        }
      } catch {
        resetStateAndRedirect(`${source} | fn: validation fails`);
      }
    },
    [dispatch, resetStateAndRedirect, sitePortId, updatePickingState]
  );

  // effects and polling
  // initial page load
  useEffect(() => {
    // fetch port status, fetch picking state, see if bin is opened with picking state
    void fetchPortStatusAndUpdateOverallState("page load");
    if (!shouldListenToGridEvents) setPortPollingActive(true);

    return () => {
      resetStateAndRedirect("unmount");
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // polling 1:  timeout polling for bin arrival
  // conditions: bin arrival events are enabled
  //             the bin is not present for 3 seconds
  // time:       every 1 second after 3 seconds of bin not present
  // effects:    fetches the port state to get the selected bin (?)
  //             then fetches the log publisher state of the selected bin
  //             to determine if bin is present
  usePromiseInterval(
    async () => {
      setBinAtPortSeconds((binAtPortSecondsState) => binAtPortSecondsState + 1);
      if (binAtPortSeconds > 3) {
        const portStatus = await props.fetchPortStatus({
          portId: sitePortId || undefined
        });
        if (portStatus && selectedAutostoreGridId && portStatus.selectedBin) {
          const binStateResponse = await props.fetchBinLogPublisherState(
            selectedAutostoreGridId,
            portStatus.selectedBin
          );
          if (
            binStateResponse?.binState.binMode === "O" &&
            binStateResponse.binState.portId === sitePortId
          ) {
            props.setBinIsPresent({
              status: true,
              reporter: "bin log publisher state",
              binId: binStateResponse.binState.binId
            });
            if (ap_fusionPortScreenEnabled) {
              const portSide = parsePortSide(binStateResponse.portSide);
              props.setLeftOrRightPortActive(portSide);
            }
          }
        }
      }
    },
    1000,
    !binIsPresent && shouldListenToGridEvents && !batchCompletedModalOpen
  );

  useEffect(() => {
    if (binIsPresent && binAtPortSeconds > 0) {
      setBinAtPortSeconds(0);
    }
    if (
      binIsPresent &&
      ap_showPickToLight &&
      selectedAutostoreGridId &&
      pickingState &&
      sitePortId &&
      currentPick
    ) {
      const compartmentId = getCompartmentId(
        pickingState.binConfiguration?.configurationType || 1,
        currentPick.pickBin?.autostoreCompartmentNumber || 1
      );

      void props.showPickToLight(
        selectedAutostoreGridId,
        sitePortId,
        currentPick.pickBin?.autostoreBin?.autostoreBinId ||
          binIsPresentBinId ||
          1,
        compartmentId,
        currentPick?.quantity?.value || 1
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [binIsPresent]);

  // polling 2:  polling when signal r is disconnected
  // conditions: signal r is disconnected
  // time:       every 5 seconds
  // effects:    fetch port status, fetch picking state, see if bin is opened with picking state
  usePromiseInterval(
    () => fetchPortStatusAndUpdateOverallState("signalr disconnect"),
    5000,
    props.signalr.state === "Disconnected"
  );

  // polling 3:  fetch port state
  // conditions: port polling active and bin arrival events are disabled
  // time:       every .5 seconds
  usePromiseInterval(
    () =>
      props
        .fetchPortStatus({ portId: sitePortId || undefined })
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        .catch(() => {
          // don't stop polling if fetchPortStatus fails
        }),
    1000,
    portPollingActive && !shouldListenToGridEvents
  );

  // polling 4:  restart isHandleMoveNextPickCalled state
  // conditions: next-picking-bin already triggered from handleToteClick
  // time:       every second
  usePromiseInterval(
    () => {
      if (isHandleMoveNextPickCalledTimer.current > 6) {
        setIsHandleMoveNextPickCalled(false);
        isHandleMoveNextPickCalledTimer.current = 0;
      } else {
        isHandleMoveNextPickCalledTimer.current += 1;
      }
      return Promise.resolve();
    },
    1000,
    isHandleMoveNextPickCalled
  );

  // listen to portState for the purpose of stopping polling
  // stop polling when bin arrives
  useEffect(() => {
    if (!pickingState) return;

    // Case when our binIsPresent state is true but our polling is still active
    // and we can see from response that portState.isReady property is false
    // which means that bin is not at the port. This will cause the tote box to
    // be enabled but it shouldn't because bin is not at the port actually.
    if (
      !shouldListenToGridEvents &&
      portState &&
      !portState.isReady &&
      !pickingStateLoading &&
      currentPick?.autostoreTaskId === portState.selectedTask &&
      binIsPresent
    ) {
      props.setBinIsPresent({
        status: false,
        reporter: "port state"
      });
    }

    if (
      (!shouldListenToGridEvents &&
        portState &&
        portState.isReady &&
        !pickingStateLoading &&
        currentPick?.autostoreTaskId === portState.selectedTask) ||
      batchCompletedModalOpen
    ) {
      // bin has arrived
      setPortPollingActive(false);
      props.setBinIsPresent({
        status: true,
        reporter: "port state",
        binId: portState?.selectedBin
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [portState]);

  // picking state change
  useEffect(() => {
    if (!pickingState) return;
    if (pickingStateLoading) return;

    if (!currentPick) return;

    dispatch(setCurrentPickQuantity(currentPick.quantity.value || 0));

    // COMPARTMENT LOGIC
    setRecentBatchInfo({
      batchId: pickingState.batchId,
      batchName: pickingState.batchName,
      temperatureZone: currentPick.pickBin?.temperatureZone
    });

    const autostoreCompartmentNumber =
      currentPick.pickBin?.autostoreCompartmentNumber || 1;

    dispatch(setPickCompartment(autostoreCompartmentNumber - 1 || 0));

    if (splitPickResponse) props.restartSplitAutostore();
    if (isToteBoxClicked.current) isToteBoxClicked.current = false;
    if (ableToPickQty) dispatch(setAbleToPickQty(null));

    // We want to trace info about the last pick in the tote before the tote is
    // fully picked. We set all necessary info about picked tote. We set caller
    // property to be null because we want to update that info once the caller
    // is decided (either the 'tote picked' event or the 'confirm pick' method)
    void (async () => {
      if (ap_applyingLabelProcessEnabled) {
        const scheduledPicks = pickingState.allPicks
          .filter(
            (tote) =>
              tote.toteId === pickingState.currentPicks[0]?.assignedToteId
          )
          .map((tote) => tote.picks)
          .flat()
          .filter((pick) => pick.status.toLowerCase() === "scheduled");

        // If there is only one scheduled pick, that's the last pick in the tote
        if (scheduledPicks.length === 1) {
          const pickInfo = await fetchPick(scheduledPicks[0].pickId).unwrap();
          if (pickInfo) {
            lastPickedTote.current = {
              pickedTote: {
                pickId: pickInfo.pickId,
                toteId: pickInfo.assignedToteId || pickInfo.originalToteId,
                orderId: pickInfo.orderId
              },
              caller: null
            };
          }
        } else {
          lastPickedTote.current = {
            pickedTote: null,
            caller: null
          };
        }
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pickingState, pickingStateLoading]);

  // fetch batch for cart number if cart is not confirmed
  useEffect(() => {
    if (!pickingState) return;
    if (
      ap_confirmCartNumberEnabled &&
      !cartNumberConfirmed &&
      !batchWithCartNumber
    ) {
      void props.fetchBatchWithCartNumber(pickingState.batchName);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cartNumberConfirmed, pickingState]);

  // redirect when "there is nothing left to pick" is received as an error message.
  useEffect(() => {
    const messageExists = userMessages.some((messageObj) =>
      messageObj.title?.includes(noBatchesLeftMessage)
    );
    const pickWasNotInScheduledStatusMessageExists = userMessages.find(
      (message) =>
        message.title?.toLowerCase().trim() ===
        "the pick was not in the scheduled status"
    );
    if (messageExists && !pickedTote && !batchCompletedModalOpen) {
      resetStateAndRedirect("nothing left to pick in user message", () => {
        successToast("Port successfully closed.");
        navigate("/autostore-main");
      });
    }
    void (async () => {
      // if 'The pick was not in the scheduled status' message appears
      // we need to check if this pick belongs to canceled order
      if (pickWasNotInScheduledStatusMessageExists && currentPick) {
        const currentPickUpdated = await fetchPick(currentPick.pickId).unwrap();
        if (
          currentPickUpdated &&
          currentPickUpdated.status.toLowerCase() === "canceled"
        ) {
          await updatePickingState({});
          if (currentPick) {
            const orderInfo = (
              await fetchOrder({
                orderId: currentPick.orderId
              }).unwrap()
            ).at(0);

            if (
              orderInfo &&
              orderInfo.status.toLowerCase() === "canceled" &&
              ap_showOrderOrProductCancellationModal
            ) {
              dispatch(openRestockPickModal());
            } else {
              if (!isServerSideNextPickingBinEnabled)
                await handleMoveNextPick({
                  reporter: "userMessages useEffect"
                });
            }
            props.clearUserMessage(pickWasNotInScheduledStatusMessageExists.id);
          }
        }
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userMessages.length]);

  useEffect(() => {
    if (fulfillmentCenter && !fulfillmentCenter.pickQuantityConfirmationEnabled)
      return;

    if ((displayTotesPrepModal || outOfStockModalStatus) && scannedBarcode)
      dispatch(resetScannedBarcode());

    if (
      !!scannedBarcode &&
      !!currentPick &&
      !!currentPickUpcs &&
      !displayTotesPrepModal &&
      !outOfStockModalStatus &&
      matchExactUpcsOrSkus(
        [...currentPickUpcs, currentPick.sku],
        scannedBarcode
      ) &&
      isScanLabelModalHidden &&
      !isPickQuantityConfirmed
    ) {
      dispatch(
        setPickedQuantityModalStatus({
          isShown: true,
          pickedQuantity: currentPick.quantity.value,
          selectedBinId:
            currentPick.pickBin?.autostoreBin?.autostoreBinId || null
        })
      );
      dispatch(resetScannedBarcode());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scannedBarcode, outOfStockModalStatus]);

  // Set port side
  useEffect(() => {
    void (async () => {
      if (
        binIsPresent &&
        ap_fusionPortScreenEnabled &&
        leftOrRightPortActive === "Unknown" &&
        selectedAutostoreGridId &&
        binIsPresentBinId
      ) {
        const newPortSideInfo = await props.fetchBinLogPublisherState(
          selectedAutostoreGridId,
          binIsPresentBinId
        );
        if (newPortSideInfo) {
          const portSide = parsePortSide(newPortSideInfo.portSide);
          props.setLeftOrRightPortActive(portSide);
        }
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [binIsPresent, ap_fusionPortScreenEnabled, leftOrRightPortActive]);

  // This useEffect covers situation when we do partial oos on first pick and
  // there is no inventory for rest of the picks in the batch. In that case, we
  // should display scan label for that partially oos tote and after label is
  // scanned and applied, we should close the port end navigate to
  // autostore-main page.
  useEffect(() => {
    if (
      ap_applyingLabelProcessEnabled &&
      isScanLabelModalHidden &&
      !!allPicksCompletedErrorMessage &&
      allPicksCompletedErrorMessage === "No batches are available to pick" &&
      !batchCompletedModalOpen
    ) {
      resetStateAndRedirect("nothing left to pick in user message", () => {
        successToast("Port successfully closed.");
        navigate("/autostore-main");
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isScanLabelModalHidden, allPicksCompletedErrorMessage]);

  // In case that Qubit ends up in a situation where the 'scan & apply label'
  // modal and the 'pick quantity confirmation' modal are displayed at the same
  // time. We want to close the 'pick quantity confirmation' modal immediately
  // and keep displaying only 'scan & apply label' modal.
  useEffect(() => {
    if (isScanAndApplyLabelModalShown && confirmPickQuantityModalIsShown) {
      dispatch(
        setPickedQuantityModalStatus({
          isShown: false,
          pickedQuantity: null,
          selectedBinId: null
        })
      );
      if (isPickQuantityConfirmed) {
        props.setIsPickQuantityConfirmed(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isScanAndApplyLabelModalShown, confirmPickQuantityModalIsShown]);

  // If the pick canceled order is received while the OOS or Hold Bin modal is
  // open. We want only to show the Pick/Order cancellation modal since the user
  // can't OOS or put the inventory on hold anymore because the bin will go
  // away. Also if the Pick/Order cancellation modal is shown after the Pick
  // Confirmation modal is already displayed, we should close the Pick
  // Confirmation modal because the bin will go away and after that, the picker
  // should scan the product from the next bin and enter proper quantity.
  useEffect(() => {
    if (isRestockPickModalOpen && ap_showOrderOrProductCancellationModal) {
      if (outOfStockModalStatus) dispatch(setOutOfStockDialogStatus(false));
      if (problemSolveModalOpen) dispatch(closeHoldBinModal());
      if (
        fulfillmentCenter?.pickQuantityConfirmationEnabled &&
        confirmPickQuantityModalIsShown
      )
        dispatch(
          setPickedQuantityModalStatus({
            isShown: false,
            pickedQuantity: null,
            selectedBinId: null
          })
        );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isRestockPickModalOpen, ap_showOrderOrProductCancellationModal]);

  // If the totes prep modal is displayed, that means that a new batch has just
  // started. And there are no picked totes at the beginning of the picking
  // process so we can safely close the slam modal.
  useEffect(() => {
    if (displayTotesPrepModal && ap_applyingLabelProcessEnabled && pickedTote) {
      props.setPickedTote(null);
      dispatch(resetScanAndApplyModalActiveStep());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ap_applyingLabelProcessEnabled, displayTotesPrepModal, pickedTote]);

  /* BIN ARRIVAL EVENTS */
  // receive bin arrived events
  const gridSub = async (data: AutostoreEvent) => {
    if (data.case !== "BinModeChange" || data.event.binMode !== "O") return;

    if (
      data.event.gridId === selectedAutostoreGridId &&
      !!data.event.portId &&
      siteAllPortIds.includes(data.event.portId)
    ) {
      const portStatus = await props.fetchPortStatus({
        portId: sitePortId || undefined
      });
      if (portStatus && data.event.binId === portStatus.selectedBin) {
        props.setBinIsPresent({
          status: true,
          reporter: "bin opened event",
          binId: data.event.binId
        });
        if (
          ap_fusionPortScreenEnabled &&
          siteWorkstation &&
          data.event.coordinate
        ) {
          const portSide = getPortSide(siteWorkstation, data.event.coordinate);
          props.setLeftOrRightPortActive(portSide);
        }
      }
    }
  };

  useGridV2Subscription(gridSub);

  /* PICK EVENTS */
  // receive pick events (from lightning PTL presses)
  // pick events are received even when the pick comes from a tote click
  const pickSubscription = async (event: SignalRPickEventDto) => {
    if (!pickingState) return;
    if (
      ["completed", "outofstock"].includes(event.eventType.toLowerCase()) &&
      pickingState.batchId === event.batchId &&
      ((currentPick && currentPick.pickId === event.pick.pickId) ||
        (splitPickResponse &&
          splitPickResponse.splitPick.pickId === event.pick.pickId))
    ) {
      restartInactivityTimer();
      // If applying label process is enabled, we need to track which tote the
      // completed pick belongs to. When Add Tote functionality is called, we
      // will send this tote id as pickedToteId prop to ScanAndApplyLabelConfirmModal
      if (ap_applyingLabelProcessEnabled && event.pick.assignedToteId) {
        props.setTotePickingStarted(event.pick.assignedToteId);
      }

      // close 'Problem solve', 'Add tote' and/or 'Out of stock' modals if the
      // pick completed/out of stock event received
      if (problemSolveModalOpen) dispatch(closeHoldBinModal());
      if (isAddToteModalOpen) dispatch(closeAddToteModal());
      if (outOfStockModalStatus) dispatch(setOutOfStockDialogStatus(false));

      if (
        splitPickResponse &&
        !isToteBoxClicked.current &&
        event.eventType.toLowerCase() !== "outofstock"
      ) {
        await props.outOfStockPickAutostore(
          splitPickResponse.remainingPick.pickId,
          splitPickResponse.remainingPick?.pickBin?.binId || "",
          selectedAutostoreGridId || "",
          sitePortId || -1,
          outOfStockReasonCode
        );
      }

      if (!isHandleMoveNextPickCalled) {
        props.setNextPickingBinLoading(true);
        if (!isServerSideNextPickingBinEnabled) {
          await handleMoveNextPick({
            reporter: "pick event received"
          });
        }
      }

      if (isHandleMoveNextPickCalled) {
        setIsHandleMoveNextPickCalled(false);
        isHandleMoveNextPickCalledTimer.current = 0;
      }
    }

    if (
      ["outofstock", "canceled"].includes(event.eventType.toLowerCase()) &&
      pickingState.batchId === event.batchId &&
      currentPick &&
      currentPick.pickId === event.pick.pickId
    ) {
      restartInactivityTimer();
      // If applying label process is enabled, we need to track which tote the
      // out of stocked pick belongs to. When Add Tote functionality is called,
      // we will send this tote id as pickedToteId prop to ScanAndApplyLabelConfirmModal
      if (ap_applyingLabelProcessEnabled && event.pick.assignedToteId) {
        props.setTotePickingStarted(event.pick.assignedToteId);
      }
    }

    if (
      event.eventType.toLowerCase() === "canceled" &&
      pickingState.batchId === event.batchId &&
      currentPick &&
      currentPick.pickId === event.pick.pickId
    ) {
      await updatePickingState({});
      if (ap_showOrderOrProductCancellationModal)
        dispatch(openRestockPickModal());
    }

    // Selected  event will be sent as soon as we know what task the Autostore
    // Grid will present next. Before the bin arrival, just after the bin closes.

    // the pickingState data is included here and is used to immediately update the state
    if (
      event.eventType === "Selected" &&
      pickingState.batchId === event.batchId &&
      sitePortId
    ) {
      updatePickingStateFromEvent(sitePortId, event.pickingState);
      // When Qubit receives pick selected event, we want to send showPickToLight
      // command which should display Pick Qty on the physical grid
      // NOTE: This is only enabled for bookstore through client configuration
      if (ap_showPickToLight && selectedAutostoreGridId) {
        const compartmentId = getCompartmentId(
          event.pickingState.binConfiguration?.configurationType || 1,
          event.pickingState.currentPicks[0]?.pickBin
            ?.autostoreCompartmentNumber || 1
        );
        await props.showPickToLight(
          selectedAutostoreGridId,
          sitePortId,
          event.pickingState.currentPicks[0]?.pickBin?.autostoreBin
            ?.autostoreBinId ||
            binIsPresentBinId ||
            1,
          compartmentId,
          event.pickingState.currentPicks.length
            ? event.pickingState.currentPicks[0].quantity.value
            : 1
        );
      }
    }
  };

  usePickSubscription(pickSubscription);

  // For clients that are consuming SLAM process
  // Waiting for tote picked event (all picks from tote are completed) which
  // should indicate that AA needs to scan the label
  const toteSubscription = async (event: ToteEventDto) => {
    // When the last pick in the tote is completed, we have two paths for
    // updating state related to the SLAM modal:
    // 1. Update state which will show the SLAM modal when the last pick is
    // confirmed from the Qubit
    // 2. Update state which will show the SLAM modal when the 'tote picked'
    // event is received - This one is expected here
    if (
      event.eventType.toLowerCase() === "picked" &&
      (!lastPickedTote.current?.caller ||
        lastPickedTote.current.caller === "tote picked event" ||
        !lastPickedTote.current.pickedTote) &&
      ap_applyingLabelProcessEnabled
    ) {
      // Check if tote belongs to current batch
      const isPickedToteInPickingState = pickingState?.totes.find(
        (tote) => tote.toteId === event.tote.toteId
      );
      if (isPickedToteInPickingState) {
        lastPickedTote.current.caller = "tote picked event";

        // In this moment, Qubit needs updated information about Picked Tote picks
        await handleSlamProcess(
          event.tote.toteId,
          event.tote.orderId,
          event.tote,
          false,
          event.portId
        );
      }
    }

    // When added event received, we need to send parent tote id to indicate
    // proper tote for applying label
    if (event.eventType === "Added" && pickingStartedToteId) {
      // Check if tote belongs to current batch
      const pickingStateLocal = await updatePickingState({
        ignoreErrorMessage: true
      });
      if (!pickingStateLocal) return;

      const isPickedToteInPickingState = pickingState?.totes.find(
        (tote) => tote.toteId === pickingStartedToteId
      );
      // Ideally we should use gridId from the event but that value is always null
      const addedToteBelongsToCurrentBatch = pickingStateLocal?.totes.some(
        (tote) => tote.orderId === event.tote.orderId
      );
      if (
        pickingStateLocal &&
        isPickedToteInPickingState &&
        addedToteBelongsToCurrentBatch
      ) {
        const applyLabelTote = pickingStateLocal.totes.find(
          (tote) => tote.toteId === pickingStartedToteId
        );
        if (applyLabelTote) {
          await handleSlamProcess(
            applyLabelTote.toteId,
            applyLabelTote.orderId,
            applyLabelTote,
            false,
            event.portId
          );
        }
      }
    }
  };

  useToteSubscription(toteSubscription);

  const getNextBatch = async () => {
    try {
      let batchId: string | null = null;
      props.clearPickingState();
      dispatch(closeStartPickingModal());
      if (ap_confirmCartNumberEnabled) {
        props.setCartNumberConfirmed(false);
      }
      if (nextPickingBinCallerTimestamp.current)
        nextPickingBinCallerTimestamp.current = null;
      if (!!selectedAutostoreGridId && !!sitePortId) {
        if (ap_fusionPortScreenEnabled) {
          const pickingStateLocal = await props.startPickingOnWorkstation({
            enableMultiPort: showTwoBinComponents || undefined,
            preventClosePort: true,
            isStartPickingOnWorkstationEndpointEnabledInfo:
              isStartPickingOnWorkstationEndpointEnabled
          });
          if (
            pickingStateLocal &&
            ap_showPickToLight &&
            selectedAutostoreGridId &&
            sitePortId
          ) {
            const compartmentId = getCompartmentId(
              pickingStateLocal.binConfiguration?.configurationType || 1,
              pickingStateLocal.currentPicks[0]?.pickBin
                ?.autostoreCompartmentNumber || 1
            );
            await props.showPickToLight(
              selectedAutostoreGridId,
              sitePortId,
              pickingStateLocal.currentPicks[0]?.pickBin?.autostoreBin
                ?.autostoreBinId || 1,
              compartmentId,
              pickingStateLocal.currentPicks.length
                ? pickingStateLocal.currentPicks[0].quantity.value
                : 1
            );

            batchId = pickingStateLocal.batchId;
          }
        } else {
          const pickingStateLocal = await props.startPickingOnWorkstation({
            preventClosePort: true,
            isStartPickingOnWorkstationEndpointEnabledInfo:
              isStartPickingOnWorkstationEndpointEnabled
          });

          batchId = pickingStateLocal?.batchId ?? null;
        }
      }

      if (!shouldListenToGridEvents) {
        setPortPollingActive(true);
      }
      setCurrentPickScannedCount(0);
      if (
        fulfillmentCenter &&
        fulfillmentCenter.pickQuantityConfirmationEnabled
      ) {
        dispatch(
          setPickedQuantityModalStatus({
            isShown: false,
            pickedQuantity: null,
            selectedBinId: null
          })
        );
      }
      if (ap_applyingLabelProcessEnabled) {
        if (pickedTote) {
          props.setPickedTote(null);
        }
        if (pickingStartedToteId) {
          props.setTotePickingStarted(null);
        }
        lastPickedTote.current = {
          pickedTote: null,
          caller: null
        };
        if (batchTotes) props.restartTotesByBatch();
      }

      return batchId;
    } catch {
      return null;
    }
  };

  const handleHomeButtonClick = () => {
    resetStateAndRedirect("home button click", () => {
      successToast("Port successfully closed.");
      dispatch(closeStartPickingModal());
      navigate("/autostore-main");
    });
  };

  // useMemo otherwise debounce will be a new debounce when re-rendering
  const debounceUpcScan = useMemo(
    () =>
      debounce((upcToScan: string, binOpened) => {
        if (upcToScan && binOpened) {
          window.simScan(upcToScan);
        }
      }, 200),
    []
  );

  // need to wait for pick selected
  useEffect(() => {
    if (
      envConstants.ENABLE_AUTOMATED_OPERATIONS === "true" &&
      props.automatedOperationsEnabled
    ) {
      if (ap_applyingLabelProcessEnabled && currentPickUpcs && binIsPresent) {
        void debounceUpcScan(currentPickUpcs[0], binIsPresent);
      }
      if (batchCompletedModalOpen) {
        setTimeout(() => {
          void getNextBatch();
        }, 200);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    ap_applyingLabelProcessEnabled,
    currentPickUpcs,
    binIsPresent,
    batchCompletedModalOpen
  ]);

  useKeyDownHandler();
  useBarcodeScanner<boolean>({
    findScanMatch: async (initialBuffer: string) => {
      const buffer: string = getBarcodeValue(initialBuffer);
      dispatch(setScannedBarcode(buffer));

      restartInactivityTimer();

      // If 'Scan & Apply label' modal is shown, AA should scan the label (match with external tote id)
      if (!isScanLabelModalHidden && !outOfStockModalStatus) {
        const isProperLabelScanned =
          buffer === pickedTote?.externalToteId?.trim();
        const wrongLabelScannedMessage =
          "Scanned value does not match the label. Please scan the correct label";
        if (pickedTote && isProperLabelScanned) {
          const messageExits = userMessages.find(
            (message) => message.title === wrongLabelScannedMessage
          );
          if (messageExits) {
            props.clearUserMessage(messageExits.id);
          }
          setScanState("success");
          void scanLabel({
            toteId: pickedTote.toteId,
            autostorePortId: sitePortId || undefined,
            autostoreGridId: selectedAutostoreGridId,
            workstationId: siteWorkstation?.id
          });
          dispatch(incrementScanAndApplyModalActiveStep());
        }

        if (!isProperLabelScanned) {
          errorToast(wrongLabelScannedMessage);
        }
      }

      if (
        !pickingState ||
        !binIsPresent ||
        !currentPick ||
        !currentPickUpcs ||
        !isScanLabelModalHidden ||
        displayTotesPrepModal ||
        currentPick.quantity.value === currentPickScannedCount
      )
        return false;

      if (
        !!fulfillmentCenter &&
        fulfillmentCenter.pickQuantityConfirmationEnabled &&
        !!currentPickUpcs &&
        matchesUpc(currentPickUpcs, buffer)
      ) {
        if (isScanLabelModalHidden) {
          setScanState("success");
        }
        if (
          currentPick.quantity.value === 1 &&
          selectedAutostoreGridId &&
          sitePortId &&
          !isScanAndApplyLabelModalShown
        ) {
          props.setIsPickQuantityConfirmed(true);
          dispatch(
            setPickedQuantityModalStatus({
              isShown: false,
              pickedQuantity: null,
              selectedBinId: null
            })
          );
          await displayPickData({
            portId: sitePortId,
            pickId: currentPick.pickId,
            batchId: currentPick.batchId || pickingState.batchId,
            gridId: selectedAutostoreGridId
          });
        }
      }
      await verifyPick({
        upcOrSku: buffer,
        pickId: currentPick.pickId,
        verifyMethod: ScanningVerification.SCANNER,
        workstationId: siteWorkstation?.id
      })
        .unwrap()
        .catch((err: unknown) => {
          // The verifyPick API returns an error message with text: "The UPC or
          // SKU do not match the pick". This path should only match UPC so we
          // should delete SKU from the error message when a scan error occurs.
          let errorMessage = getMessageFromRtkError(err);
          const stringToReplace = "The UPC or SKU do not match the pick";
          if (errorMessage.includes(stringToReplace))
            errorMessage = errorMessage.replace(
              stringToReplace,
              "The UPC does not match the pick"
            );
          errorToast(errorMessage);
        });
      return matchesUpc(currentPickUpcs, buffer);
    },
    processScanMatch: () => {
      if (!isScanAndApplyLabelModalShown)
        setCurrentPickScannedCount(currentPickScannedCount + 1);
    },
    deps: [portPollingActive, pickingState, currentPickScannedCount],
    disabled: ap_confirmCartNumberEnabled && !cartNumberConfirmed
  });

  const matchEnteredUpcOrSku = useCallback(
    async (
      pickingState: AutostorePickingState | null,
      upcOrSku?: string,
      callback?: () => void
    ) => {
      if (!pickingState) return;
      const pickId =
        pickingState.selectedPickId || pickingState.currentPicks[0]?.pickId;
      const currentPick = pickingState.currentPicks.find(
        (currentPick) => currentPick.pickId === pickId
      );
      if (!currentPick) return;
      const finalUpcOrSku = upcOrSku || currentPick.upc || currentPick.sku;
      await verifyPick({
        upcOrSku: finalUpcOrSku,
        pickId,
        verifyMethod: ScanningVerification.INPUT,
        workstationId: siteWorkstation?.id
      })
        .unwrap()
        .catch((err) => errorToast(getMessageFromRtkError(err)));
      const allUpcsOrSkus = [...currentPick.allUpcs, currentPick.sku];
      const isScannedUpcCorrect = matchExactUpcsOrSkus(
        allUpcsOrSkus,
        finalUpcOrSku
      );
      if (!isScannedUpcCorrect) return;

      // When the pick quantity is 1, we don't want to show the pick quantity confirmation modal
      if (currentPick.quantity?.value === 1) {
        // next pr
        dispatch(setIsPickQuantityConfirmed(true));
        dispatch(
          setPickedQuantityModalStatus({
            isShown: false,
            pickedQuantity: null,
            selectedBinId: null
          })
        );

        // The user should be able to complete the pick from PTL also
        if (sitePortId)
          await displayPickData({
            portId: sitePortId,
            pickId,
            batchId: currentPick.batchId || pickingState.batchId,
            gridId: siteWorkstation?.autostoreGridId
          });
        if (callback) {
          callback();
        }
        return;
      }
      // When the pick quantity is > 1, display the pick quantity confirmation modal
      dispatch(
        setPickedQuantityModalStatus({
          isShown: true,
          pickedQuantity: currentPick.quantity?.value,
          selectedBinId:
            currentPick.pickBin?.autostoreBin?.autostoreBinId || null
        })
      );
      if (callback) {
        callback();
      }
    },
    [
      dispatch,
      errorToast,
      verifyPick,
      siteWorkstation?.autostoreGridId,
      siteWorkstation?.id,
      sitePortId
    ]
  );

  const handleProductImageClick = () => {
    if (isBinReady) {
      void matchEnteredUpcOrSku(pickingState);
    }
  };

  const ptlStyle = {
    color: ternaryIff(
      props.signalr.state === "Connected",
      "success.main",
      "autostoreRed.main"
    ),
    ...(!ap_fusionPortScreenEnabled && {
      display: "inline-block",
      marginLeft: 5
    })
  };

  useEffect(() => {
    setMenuItems([
      {
        textContent: t("refresh"),
        actionCb: () => fetchPortStatusAndUpdateOverallState("more menu click")
      },
      {
        textContent: t("check bin status"),
        actionCb: () => {
          binStatusCheck(currentPick?.pickBin?.autostoreBin?.autostoreBinId);
        }
      }
    ]);
  }, [
    fetchPortStatusAndUpdateOverallState,
    setMenuItems,
    t,
    currentPick?.pickBin?.autostoreBin?.autostoreBinId,
    binStatusCheck
  ]);

  useEffect(() => {
    setInformationalMenuItems([
      ...(batchName && taskGroupId
        ? [
            {
              textContent: ` ${t("batch")}: ${batchName} `
            },
            {
              textContent: `${t("task group id")}: ${taskGroupId}`
            }
          ]
        : [])
    ]);
  }, [batchName, setInformationalMenuItems, t, taskGroupId]);

  // If the inactivity timer is enabled, we don't want to track it when the SLAM
  // modal is displayed since this workflow is hard to recover from and the user
  //  needs to stay on the picking page while the modal is displayed
  useEffect(() => {
    if (isScanAndApplyLabelModalShown) {
      clearInactivityTimer();
    }

    if (!isScanAndApplyLabelModalShown) {
      restartInactivityTimer();
    }
  }, [
    isScanAndApplyLabelModalShown,
    clearInactivityTimer,
    restartInactivityTimer
  ]);

  // Update the batch progress bar once the current batch is finished
  useEffect(() => {
    if (batchCompletedModalOpen && areShipmentsEnabled)
      void refetchGetWaveProgress();
  }, [batchCompletedModalOpen, refetchGetWaveProgress, areShipmentsEnabled]);

  useEffect(() => {
    if (areShipmentsEnabled && isWaveProgressBarError) {
      errorToast(getMessageFromRtkError(waveProgressBarError));
    }
  }, [
    areShipmentsEnabled,
    isWaveProgressBarError,
    waveProgressBarError,
    errorToast
  ]);

  const simulateUpcScanFeatureFlag = useFlag().simulateUpcScan;

  const isSimulateUpcScanFeatureFlagEnabled =
    !!fulfillmentCenter &&
    fulfillmentCenter.pickQuantityConfirmationEnabled &&
    simulateUpcScanFeatureFlag;

  const isBinReady =
    isSimulateUpcScanFeatureFlagEnabled &&
    binIsPresent &&
    !isPickQuantityConfirmed;

  const upc = currentPickUpcs?.[0] || "";

  return (
    <>
      <Grid
        id="AP_main_container"
        container
        spacing={1}
        sx={{ marginTop: "40px", padding: "5px", overflowX: "hidden" }}
      >
        <TopActionButtons
          currentPick={currentPick}
          handleFetchNextPickBin={handleFetchNextPickBin}
        />
        <Grid
          id="AP_inner_window"
          item
          xs={12}
          md={12}
          sx={{ display: "flex", width: "100%" }}
        >
          {/* Bin and Product column  */}
          <Grid
            id="AP_bin_product_column"
            item
            xs={4}
            sx={
              totesPlacement === "split"
                ? {
                    position: "absolute",
                    right: 0,
                    margin: "0 auto",
                    left: 0,
                    marginTop: "-40px"
                  }
                : { marginTop: "40px" }
            }
          >
            <PickingBins currentPick={currentPick} />
            <Box id="pickInfo_container" style={{ marginTop: "1.25em" }}>
              {pickingState && currentPick ? (
                <ProductCard.Root
                  productName={currentPick.name}
                  quantity={inventory?.count?.value}
                  imageFileName={currentPick.imageFilename}
                  onProductImageClick={handleProductImageClick}
                  disabled={!isBinReady}
                >
                  {fulfillmentCenter?.pickQuantityConfirmationEnabled && (
                    <BarcodeInput matchEnteredUpcOrSku={matchEnteredUpcOrSku} />
                  )}
                  <ProductCard.Sku sku={currentPick.sku} />
                  <ProductCard.ProductCount
                    count={{
                      value: currentPick.unitAmount,
                      units: currentPick.unitFormatted
                    }}
                  />
                  {!!fulfillmentCenter &&
                  fulfillmentCenter.pickQuantityConfirmationEnabled ? undefined : (
                    <ProductCard.CompletionOverlay
                      completionPercentage={Math.ceil(
                        (currentPickScannedCount / currentPick.quantity.value) *
                          100
                      )}
                    />
                  )}
                  {envConstants.SHOW_SCANNABLE_BAR_CODES === "true" && (
                    <ProductCard.QRCode upc={upc} />
                  )}
                </ProductCard.Root>
              ) : (
                <ProductCard.Loading innerHeight="32vH" />
              )}
            </Box>
          </Grid>
          <AutostorePickingTotes handleToteClick={handleToteClick} />
        </Grid>
        {/* end of pick container */}
      </Grid>
      {/* end of main container */}
      <div
        id="ptl_info_container"
        style={{
          width: ap_fusionPortScreenEnabled ? 100 : 200,
          position: "fixed",
          bottom: 20,
          right: 15,
          textAlign: ap_fusionPortScreenEnabled ? "left" : "right"
        }}
      >
        {t("ptl")}:
        <Typography sx={ptlStyle}>
          {t(
            `${props.signalr.state.toLowerCase()}` as
              | "connected"
              | "disconnected"
          )}
        </Typography>
      </div>
      {(displayTotesPrepModal ||
        displayToteConfirmationModalForSpecificTote) && (
        <TotesPrepModal
          originateTotes={originalTotes}
          restartInactivityTimer={restartInactivityTimer}
        />
      )}
      <StartPickingModal
        open={
          batchCompletedModalOpen &&
          isScanLabelModalHidden &&
          !isRestockPickModalOpen
        }
        onHomeButtonClickCallback={handleHomeButtonClick}
        onNextPickButtonClickCallback={getNextBatch}
        maxWidth="sm"
        title={t("batch complete")}
        batchName={recentBatchInfo?.batchName}
        text={
          pickingConfigurations?.includes("Manual")
            ? `Move Cart to ${
                recentBatchInfo?.temperatureZone || ""
              } Autostore Staging Area`
            : t("pick next batch")
        }
        pickButtonText={t("start new pick")}
      />
      {outOfStockModalStatus && (
        <AutostoreOutOfStockDialog handleMoveNextPick={handleMoveNextPick} />
      )}
      {isCartNumberConfirmationShown && (
        <CartNumberConfirmationModal
          maxWidth="md"
          resetState={resetStateAndRedirect}
        />
      )}
      {isPickQuantityConfirmationFlowEnabled && (
        <ConfirmPickQuantityModal
          open={confirmPickQuantityModalIsShown}
          maxWidth="lg"
        />
      )}
      {isScanAndApplyLabelModalShown && (
        <ScanAndApplyLabelConfirm
          originalTotes={originalTotes}
          addedTotes={addedTotes}
          restartInactivityTimer={restartInactivityTimer}
        />
      )}
      {ap_scanningIndicatorShown && (
        <ScanningIndicator
          scanState={scanState}
          scannedBarcode={scannedBarcode}
          placeholderText="Scan Product"
          position="left"
        />
      )}
      <AddToteModal confirmPickCallback={handleToteClick} />
      {ap_showOrderOrProductCancellationModal && isRestockPickModalOpen && (
        <RestockPickModal
          handleContinuePickingButtonClick={async () => {
            if (!isServerSideNextPickingBinEnabled)
              await handleMoveNextPick({
                reporter: "order cancellation modal"
              });
          }}
        />
      )}
    </>
  );
}

export default connector(AutostorePicking);
