import {
  Card,
  CardActionArea,
  CardProps,
  Container,
  Typography
} from "@mui/material";
import { styled } from "@mui/material/styles";

import { Stack } from "@mui/system";

import { mergeSx } from "merge-sx";
import { ReactNode } from "react";
import { useTranslation } from "react-i18next";

import { CardMediaWithFallback } from "@qubit/autoparts";

const ProductName = styled(Typography)(() => ({
  fontSize: "1.4em"
}));

type Props = CardProps & {
  productName: string;
  imageFileName: string;
  /** Renders quantity "on hand" below image */
  quantity?: number;
  disableGutters?: boolean;
  /** Optionally render a section under the image. Used for Putaway quantity and date fields */
  bottomSection?: ReactNode;
  onProductImageClick?: () => void;
  disabled?: boolean;
};

export function Root({
  productName,
  imageFileName,
  quantity,
  disableGutters,
  bottomSection,
  onProductImageClick,
  disabled,
  children,
  sx: sxProp,
  ...rest
}: Props) {
  const { t } = useTranslation();

  const sx = {
    position: "relative",
    padding: "16px"
  };

  return (
    <Container disableGutters={disableGutters}>
      <Card sx={mergeSx(sx, sxProp)} {...rest}>
        <Stack
          id="productInfo"
          sx={{ paddingBottom: "16px" }}
          gap={1}
          height="100%"
        >
          <Typography role="heading" variant="h5">
            {productName}
          </Typography>
          {children}
          <CardActionArea
            aria-label="product"
            disabled={disabled}
            onClick={onProductImageClick}
            sx={{ overflow: "hidden" }}
          >
            <CardMediaWithFallback
              image={imageFileName}
              title="product"
              sx={{ height: "100%", maxHeight: "200px" }}
            />
          </CardActionArea>
          {quantity && (
            <Stack id="quantity" alignItems="flex-end">
              <ProductName
                style={
                  sxProp && Object.hasOwn(sxProp, "height")
                    ? { position: "absolute", bottom: "0.625em" }
                    : {}
                }
              >
                <span
                  style={{ textDecoration: "underline" }}
                >{`${quantity}`}</span>
                {` ${t("on hand")}`}
              </ProductName>
            </Stack>
          )}
          {bottomSection}
        </Stack>
      </Card>
    </Container>
  );
}
