import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Autocomplete,
  Dialog,
  DialogTitle,
  SelectChangeEvent,
  Stack,
  useMediaQuery
} from "@mui/material";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import { styled } from "@mui/material/styles";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import {
  mobileWidth,
  ProgressButton,
  formatUtcDayjsMaybe,
  globalDateFormat,
  useToast
} from "@qubit/autoparts";
import dayjs from "dayjs";
import { ChangeEvent, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

import { getUserClientId } from "~/api/usersTypes/auth0Profile";
import { useAppDispatch, useAppSelector } from "~/app/store";

import { useDebounceSearch } from "~/hooks/useDebounce";

import { useBarcodeScanner } from "~/lib/barCodeScan";

import {
  variantToDisplayName,
  getInventoryDateObjDayjs,
  inventoryDateLabel,
  isAutostoreView,
  isExpirationValid,
  searchProduct
} from "~/lib/helpers";

import {
  closeBin,
  closePort,
  setSelectedAutostoreBinId
} from "~/redux/actions/autostore";
import {
  clearSelectedInventoryId,
  clearSelectedVariant,
  getVariantByVariantId,
  putAway
} from "~/redux/actions/inventory";
import { selectClientConfig } from "~/redux/selectors/siteSelectors";
import {
  useLazyGetBinsByAutostoreBinNumberQuery,
  useLazyGetBinsBySearchQuery
} from "~/redux/warehouse/bin.hooks";
import { useLazyGetLastPickedUOMQuery } from "~/redux/warehouse/variant.hooks";
import {
  ProductSearchProduct,
  ProductSearchVariant,
  SearchBinRecord,
  VariantFrontendDto
} from "~/types/api";

const IncrementButton = styled(IconButton)(({ theme }) => ({
  color: theme.palette.primary.main,
  background: "white",
  border: `2px solid ${theme.palette.primary.main}`,
  marginRight: 10,
  "&:hover": {
    backgroundColor: theme.palette.secondary
  }
}));

type InventoryAddDialogProps = {
  /** If the variant does not exist, it means that the search should be displayed and select the desired product to add */
  autostorePortId: number | null;
  open: boolean;
  variant?: VariantFrontendDto;
  binId?: Guid | null;
  isFetchingBin?: boolean;
  disableClosePortOnClose?: boolean;
  onClose: () => void;
  refreshCb: () => void;
  getEmptyBin?: () => Promise<void>;
};

type UomInfo = {
  value: string;
  label: string;
};

export function InventoryAddDialog(props: InventoryAddDialogProps) {
  const {
    autostorePortId,
    binId,
    variant,
    isFetchingBin,
    disableClosePortOnClose,
    open
  } = props;

  // Misc hooks
  const isMobile = useMediaQuery(mobileWidth);
  const { t } = useTranslation();
  const { errorToast } = useToast();
  const dispatch = useAppDispatch();
  const { search } = useLocation();

  // RTK Query hooks
  const [getBinsByAutostoreBinNumber] =
    useLazyGetBinsByAutostoreBinNumberQuery();
  const [searchBinsQuery] = useLazyGetBinsBySearchQuery();
  const [getLastPickedUOM] = useLazyGetLastPickedUOMQuery();

  // Redux state
  const selectedAutostoreGridId = useAppSelector(
    (state) => state.workstations.siteWorkstation?.autostoreGridId
  );
  const selectedAutostoreBinId = useAppSelector(
    (state) => state.autostore.selectedAutostoreBinId
  );
  const selectedInventoryId = useAppSelector(
    (state) => state.inventory.selectedInventoryId
  );
  const clientId = useAppSelector((state) =>
    getUserClientId(state.login.profile)
  );
  const selectedVariant = useAppSelector(
    (state) => state.inventory.selectedVariant
  );
  const usersFulfillmentCenter = useAppSelector(
    (state) => state.store.usersFulfillmentCenter
  );
  const currentEmptyBin = useAppSelector(
    (state) => state.autostore.currentEmptyBin
  );

  const clientConfig = useAppSelector(selectClientConfig);

  const thisWorkstationId = useAppSelector(
    (state) => state.workstations.siteWorkstation?.id
  );
  const {
    inv_addSetExpirationEnabled,
    inv_addUomSelectionForASBinsDisabled,
    inv_addUseUomFromFC,
    inv_inventoryDateLabel,
    inv_uomOptions,
    inv_inventoryDateRequired
  } = clientConfig;

  // Local state
  const [productInputSearch, setProductInputSearch] = useState<string | null>(
    null
  );
  const [productInputValue, setProductInputValue] =
    useState<ProductResult | null>(null);
  const [quantity, setQuantity] = useState<number | null>(null);
  const [listOfUoms, setListOfUoms] = useState<UomInfo[]>([]);
  const [unitOfMeasure, setUOM] = useState<string | null>();
  const [options, setOptions] = useState<SearchBinRecord[] | []>([]);
  const [selectedBin, setSelectedBin] = useState<SearchBinRecord | null>(null);
  const [inventoryDate, setInventoryDate] = useState<dayjs.Dayjs | null>(null);
  const [binFieldValue, setBinFieldValue] = useState<string | null>(null);
  const [binIdState, setBinIdState] = useState(selectedAutostoreBinId);
  const [autocompleteOptions, updateAutocompleteOptions] = useState<
    ProductResult[]
  >([] as ProductResult[]);

  const debouncedInput = useDebounceSearch(binFieldValue);
  type ProductResult = {
    type: string;
    variantId: Guid;
    displayText: string;
  };

  const disabled =
    (!variant && !selectedVariant) ||
    !quantity ||
    !isExpirationValid(
      inv_inventoryDateRequired || selectedVariant?.isExpirationRequired,
      inventoryDate
    ) ||
    (!selectedBin && !selectedAutostoreBinId && !binIdState);

  const handleQuantityChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setQuantity(event.target.value ? Number(event.target.value) : null);
  };

  const handleBinIdChange = (event: ChangeEvent<HTMLInputElement>): void => {
    if (!event.target.value && !!currentEmptyBin?.openBinResponse.binId) {
      setBinIdState(null);
    } else {
      setBinIdState(
        event.target.value
          ? Number(event.target.value)
          : currentEmptyBin && currentEmptyBin?.openBinResponse.binId
      );
    }
  };

  const handleUOMChange = (event: SelectChangeEvent<unknown>) => {
    if (typeof event?.target?.value === "string") {
      setUOM(event.target.value);
    }
  };

  const binSearchResponse = useCallback(
    async (binSearchText: string) => {
      if (isAutostoreView(search) && selectedAutostoreGridId) {
        // if at autostore port, search for autostore bins by selected grid
        return await getBinsByAutostoreBinNumber({
          autostoreGridId: selectedAutostoreGridId,
          binNumber: binSearchText
        }).unwrap();
      }
      // else if not at port, search all bins
      return await searchBinsQuery({ queryString: binSearchText }).unwrap();
    },
    [
      selectedAutostoreGridId,
      search,
      getBinsByAutostoreBinNumber,
      searchBinsQuery
    ]
  );

  const searchBins = useCallback(
    async (input: string): Promise<void> => {
      if (input.length) {
        const binSearch = await binSearchResponse(input);

        if (binSearch !== null && binSearch && binSearch.length === 1) {
          setSelectedBin(binSearch[0]);
        }
        if (binSearch) setOptions(binSearch);
      }
    },
    [binSearchResponse]
  );

  const createProductSearchOptions = (args: {
    hits: ProductSearchProduct[];
    exactUpcMatchFilter?: string;
  }): {
    type: string;
    variantId: string;
    displayText: string;
  }[] => {
    const { hits, exactUpcMatchFilter } = args;
    return hits
      .filter((hit: ProductSearchProduct) => {
        if (
          exactUpcMatchFilter &&
          ![...(hit.alternateLookups || []), hit.upc].includes(
            exactUpcMatchFilter
          )
        )
          return false;
        return true;
      })
      .flatMap((hit) =>
        hit.variants.map((v: ProductSearchVariant) => ({
          type: "product",
          variantId: v.productVariantId,
          displayText: variantToDisplayName(v, hit.name)
        }))
      );
  };

  const autocompleteSearchProducts = useCallback(
    async (input: string | null): Promise<void> => {
      if (!input) {
        return;
      }

      if (input.length && clientId) {
        const hits = await searchProduct(input);

        const hitsAsAutocompleteRecords = createProductSearchOptions({
          hits
        });
        updateAutocompleteOptions([...hitsAsAutocompleteRecords]);
      }
    },
    [clientId]
  );
  const debouncedSearch = useDebounceSearch(productInputSearch);
  useEffect(() => {
    if (open) {
      const searchFunction = async () =>
        await autocompleteSearchProducts(debouncedSearch);
      void searchFunction();
    }
  }, [debouncedSearch, autocompleteSearchProducts, open]);

  const handleAutocompleteSelect = async (
    option: ProductResult | null
  ): Promise<void> => {
    if (!option?.variantId) return;

    // eslint-disable-next-line @typescript-eslint/no-floating-promises -- TODO: await this
    dispatch(getVariantByVariantId(option.variantId));
    setProductInputValue(option);

    const uomResult = await getLastPickedUOM({
      variantId: option.variantId
    }).unwrap();

    /**
     * Set the selected UOM to what we got from Warehouse.
     * If we didn't get anything, default to the default UOM selection.
     * This will avoid weirdness if we switch from a `cd` to an `ea`
     * but don't have any adjustments for some reason.
     *
     * If we DID get something back from Warehouse, but it's not valid, also use the default UOM selection.
     * */
    const uom =
      uomResult && inv_uomOptions?.some((u: UomInfo) => u.label === uomResult)
        ? uomResult
        : inv_uomOptions?.[0]?.label;

    if (uom) setUOM(uom);
  };

  // if bin field is enabled - handle bin search on scan
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  let barcodeScanProperties: Parameters<typeof useBarcodeScanner<any>>[0];
  if (variant) {
    barcodeScanProperties = {
      disabled: !open,
      findScanMatch: async (buffer: string) => {
        setBinFieldValue(null); // set the bin field value to null, so that the inputs version of bin search doesn't also run
        const binRecords = await binSearchResponse(buffer);

        return binRecords && binRecords.length === 1 ? binRecords[0] : null;
      },
      processScanMatch: (matchedBin: SearchBinRecord | null) => {
        if (matchedBin) {
          setOptions([matchedBin]);
          setSelectedBin(matchedBin);
        }
      }
    };
  } else {
    // else, handle product search on scan
    barcodeScanProperties = {
      findScanMatch: async (buffer: string) => {
        const hits = clientId ? await searchProduct(buffer) : [];

        return hits.length
          ? createProductSearchOptions({
              hits
            })
          : false;
      },
      processScanMatch: (match: ProductResult[]) => {
        if (match) {
          // eslint-disable-next-line @typescript-eslint/no-floating-promises -- TODO: await this
          handleAutocompleteSelect(match[0]);
        }
      }
    };
  }

  useBarcodeScanner(barcodeScanProperties);

  const closeModal = async () => {
    props.refreshCb();
    props.onClose();
    if (selectedAutostoreGridId && autostorePortId && binIdState) {
      if (disableClosePortOnClose) return;
      await dispatch(
        closeBin({
          binId: binIdState
        })
      );
      await dispatch(closePort());
    }
  };

  const clearModalState = () => {
    setQuantity(null);
    setProductInputValue(null);
    setInventoryDate(null);
    setBinFieldValue(null);
    setSelectedBin(null);
  };

  useEffect(() => {
    if (open && !isAutostoreView(search))
      dispatch(setSelectedAutostoreBinId(null));
  }, [selectedAutostoreBinId, open, dispatch, search]);

  useEffect(() => {
    if (open && currentEmptyBin?.openBinResponse.binId) {
      setBinFieldValue(String(currentEmptyBin?.openBinResponse.binId));
      setBinIdState(currentEmptyBin?.openBinResponse.binId);
    }
  }, [currentEmptyBin?.openBinResponse.binId, open]);

  useEffect(() => {
    if (open && debouncedInput) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises -- TODO: await this
      searchBins(debouncedInput);
    }
  }, [debouncedInput, open, searchBins]);

  /* If not inv_addUseUomFromFC, then use hard-coded UoM data. Otherwise,
  if the response for the current fulfillment center contains UoM data,
  use it and allow the user to select UoM from the drop-down menu and force the user to select a date. */
  useEffect(() => {
    if (
      inv_addUseUomFromFC &&
      usersFulfillmentCenter &&
      usersFulfillmentCenter?.availableUoms
    ) {
      const data = usersFulfillmentCenter.availableUoms.map((value) => ({
        value,
        label: value
      }));

      setListOfUoms([...data]);
      setUOM(data?.[0]?.label);
    } else {
      if (inv_addSetExpirationEnabled)
        setInventoryDate(dayjs().utc().add(1, "year"));
      setListOfUoms([...inv_uomOptions]);
      setUOM(inv_uomOptions?.[0]?.label);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const handleAddInventory = async (): Promise<Promise<void> | null> => {
    let autostoreBin = null;
    if (binIdState) {
      const binSearch = await binSearchResponse(binIdState.toString());
      if (!selectedBin && binSearch) {
        autostoreBin = binSearch.find((b) => b.bin_id === binId);
      }
      if (binSearch && binSearch.length === 0) {
        errorToast(`Bin with bin id: ${binIdState} doesn't exist`);
      }
    }
    if (
      (selectedBin || autostoreBin) &&
      (variant || selectedVariant) &&
      quantity &&
      isExpirationValid(
        inv_inventoryDateRequired || selectedVariant?.isExpirationRequired,
        inventoryDate
      ) &&
      unitOfMeasure
    ) {
      return dispatch(
        putAway(
          {
            autostoreGridId: selectedAutostoreGridId || undefined,
            autostorePortId: autostorePortId || undefined,
            binId: autostoreBin?.bin_id ?? selectedBin?.bin_id ?? "",
            variantId: variant?.variantId ?? selectedVariant?.variantId ?? "",
            count: { value: quantity, units: unitOfMeasure },
            ...getInventoryDateObjDayjs(inv_inventoryDateLabel, inventoryDate),
            lotNumber: null as unknown as string
          },
          thisWorkstationId
        )
      ).then(async () => {
        await closeModal();
        clearModalState();
      });
    }
    return null;
  };

  const quantityTextField = (
    <TextField
      id="quantity"
      label={t("quantity")}
      type="number"
      variant="outlined"
      value={quantity || ""}
      onChange={handleQuantityChange}
      inputProps={{
        sx: { fontSize: "50px", padding: "35px 10px 35px 18px" }
      }}
      InputLabelProps={{
        shrink: true
      }}
    />
  );

  const quantityButtons = (
    <Stack spacing={1} position="absolute" margin={1}>
      <IncrementButton
        aria-label="increase-button"
        onClick={(): void => setQuantity(quantity ? quantity + 1 : 1)}
        size="large"
      >
        <ExpandLessIcon
          style={{
            fontSize: 27
          }}
        />
      </IncrementButton>
      <IncrementButton
        aria-label="decrease-button"
        onClick={(): void => setQuantity(quantity ? quantity - 1 : 0)}
        size="large"
      >
        <ExpandMoreIcon
          style={{
            fontSize: 27
          }}
        />
      </IncrementButton>
    </Stack>
  );

  const unitOfMeasureField = (
    <TextField
      id="unitOfMeasure"
      label="UOM"
      type="string"
      select
      fullWidth
      variant="outlined"
      value={unitOfMeasure || ""}
      sx={{ flex: "1 0 200px" }}
      InputLabelProps={{
        shrink: true
      }}
      inputProps={{
        sx: { fontSize: "50px", padding: "50px 10px 20px 18px" }
      }}
      SelectProps={{
        onChange: handleUOMChange
      }}
      // disable UOM selection for autostore bins when config is true
      disabled={
        !!selectedAutostoreBinId && inv_addUomSelectionForASBinsDisabled
      }
    >
      {listOfUoms.map((choiceObj, i, arr) => (
        <MenuItem
          key={`uom-option-${choiceObj.value}`}
          className={`uom-option-${i}`}
          value={choiceObj.value}
          sx={{
            padding: "10px",
            fontSize: "24px",
            borderBottomColor: "gray.light",
            borderBottom: i === arr.length - 1 ? `1px solid` : "none"
          }}
        >
          {choiceObj.label}
        </MenuItem>
      ))}
    </TextField>
  );

  const binIdField = (
    <TextField
      id="autostoreBinId"
      label={`${t("bin")} Id`}
      type="string"
      fullWidth
      variant="outlined"
      value={binIdState || ""}
      onChange={handleBinIdChange}
      InputLabelProps={{
        shrink: true
      }}
      disabled={!variant}
    />
  );

  const inventoryDatePicker = (
    <DesktopDatePicker
      slotProps={{
        textField: {
          InputLabelProps: {
            shrink: true
          },
          label: t(inventoryDateLabel(inv_inventoryDateLabel)),
          variant: "outlined",
          fullWidth: true,
          error: !isExpirationValid(
            inv_inventoryDateRequired || selectedVariant?.isExpirationRequired,
            inventoryDate
          )
        }
      }}
      label={t(inventoryDateLabel(inv_inventoryDateLabel))}
      value={dayjs(formatUtcDayjsMaybe(inventoryDate))}
      format={globalDateFormat}
      onChange={setInventoryDate}
    />
  );

  const AddInventoryButton = (
    <ProgressButton
      id="add-button"
      buttonSize="xLarge"
      emphasis="high"
      responsive
      variant="contained"
      color="primary"
      fullWidth
      startIcon={<AddIcon style={{ fontSize: 22 }} />}
      disabled={disabled || (isAutostoreView(search) && isFetchingBin)}
      onClick={async (): Promise<void | null> => handleAddInventory()}
    >
      {t("add")}
    </ProgressButton>
  );

  const BinNotEmpty = (
    <ProgressButton
      id="bin-not-empty-button"
      data-testid="bin-not-empty-button"
      buttonSize="xLarge"
      emphasis="high"
      responsive
      variant="contained"
      color="primary"
      fullWidth
      disabled={!binIdState || isFetchingBin}
      onClick={async () => {
        if (props.getEmptyBin && isAutostoreView(search))
          await props.getEmptyBin();
        if (selectedInventoryId) dispatch(clearSelectedInventoryId());
        dispatch(clearSelectedVariant());
      }}
    >
      {t("bin not empty")}
    </ProgressButton>
  );

  return (
    <Dialog onClose={closeModal} open={open} maxWidth="tablet">
      <DialogTitle sx={{ position: "relative" }}>
        <Stack>
          {t("add inventory")}
          {props.onClose && (
            <IconButton
              onClick={props.onClose}
              aria-label="close"
              sx={{ position: "absolute", right: 1 }}
            >
              <CloseIcon sx={{ color: "primary.main" }} />
            </IconButton>
          )}
        </Stack>
      </DialogTitle>
      <Stack spacing={2} padding={4}>
        {!variant && (
          <Autocomplete
            options={autocompleteOptions}
            filterOptions={(ops) => ops}
            getOptionLabel={(option) => option.displayText}
            style={{ width: "100%" }}
            onChange={(_e, option) => {
              // eslint-disable-next-line @typescript-eslint/no-floating-promises -- TODO: await this
              handleAutocompleteSelect(option);
            }}
            value={productInputValue}
            onInputChange={(_e, newInputValue) => {
              setProductInputSearch(newInputValue);
            }}
            inputValue={productInputSearch || ""}
            isOptionEqualToValue={(option, value): boolean =>
              option.variantId === value.variantId
            }
            clearOnBlur={false}
            popupIcon={null}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                type="text"
                placeholder={t("search products")}
              />
            )}
          />
        )}

        {!selectedAutostoreBinId && (
          <Autocomplete<SearchBinRecord>
            options={options}
            id="bin-search"
            filterOptions={(ops) => ops}
            getOptionLabel={(option: SearchBinRecord): string =>
              option.location
            }
            style={{
              background: "white",
              margin: "14px 3px"
            }}
            onChange={(
              _e: ChangeEvent<unknown>,
              option: SearchBinRecord | null
            ): void => {
              setSelectedBin(option);
              setBinFieldValue(null);
            }}
            inputValue={selectedBin?.location || binFieldValue || ""}
            value={selectedBin}
            popupIcon={null}
            blurOnSelect
            renderInput={(params) => (
              <TextField
                {...params}
                onChange={(e): void => {
                  // remove zebra check scan digit ("*")
                  setBinFieldValue(e.target.value.replace("*", ""));
                }}
                placeholder="Search Bin"
                variant="outlined"
                fullWidth
              />
            )}
          />
        )}
        {selectedAutostoreBinId && binIdField}
        <Stack spacing={2} direction="row">
          <Box
            position="relative"
            display="flex"
            justifyContent="end"
            alignItems="center"
            sx={{ flex: "3 0 400px" }}
          >
            {quantityTextField}
            {!isMobile && quantityButtons}
          </Box>
          {unitOfMeasureField}
        </Stack>

        {inventoryDatePicker}
      </Stack>
      <Stack spacing={2} padding={4} style={{ marginTop: isMobile ? 20 : 0 }}>
        {AddInventoryButton}
        {isAutostoreView(search) && BinNotEmpty}
      </Stack>
    </Dialog>
  );
}
