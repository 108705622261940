import CheckmarkCircle24Px from "@locaisolutions/icons/dist/icons24px/CheckmarkCircle24Px";
import Error24Px from "@locaisolutions/icons/dist/icons24px/Error24Px";
import Info24Px from "@locaisolutions/icons/dist/icons24px/Info24Px";
import Warning24Px from "@locaisolutions/icons/dist/icons24px/Warning24Px";
import {
  Alert,
  Collapse,
  SvgIcon,
  SxProps,
  styled,
  AlertColor,
  useTheme,
  Typography
} from "@mui/material";
import { mergeSx } from "merge-sx";
import { ReactNode } from "react";

type AlertBannerProps = {
  severity?: AlertColor;
  children?: ReactNode;
  sx?: SxProps;
};

const StyledAlert = styled(Alert, {
  shouldForwardProp: (propName: string) =>
    !["backgroundColor", "borderColor"].includes(propName)
})<{ backgroundColor: string; borderColor: string }>(
  ({ backgroundColor, borderColor }) => ({
    backgroundColor,
    border: `1px solid ${borderColor}`,
    paddingLeft: "48px",
    paddingRight: "48px",
    display: "inline-flex",
    alignItems: "center",
    gap: "12px"
  })
);

const StyledTypography = styled(Typography)`
  color: black;
  font-size: 1.25rem;
  line-height: 24px;
`;

const severityToIconMap = {
  error: <SvgIcon component={Error24Px} width={6} height={6} />,
  warning: <SvgIcon component={Warning24Px} width={6} height={6} />,
  info: <SvgIcon component={Info24Px} width={6} height={6} />,
  success: <SvgIcon component={CheckmarkCircle24Px} width={6} height={6} />
} as const;

/**
 * A banner meant for displaying a message which can be used as a full-width
 * banner or a smaller inline banner.
 *
 * Controlling the severity prop will change the color of the banner and the
 * icon shown inside of it. Defaults to an error banner.
 */
export const AlertBanner = ({
  severity = "error",
  children,
  sx
}: AlertBannerProps) => {
  const { palette } = useTheme();

  const styles = {
    ".MuiAlert-icon > svg": {
      fontSize: "30px"
    }
  };

  return (
    <Collapse in={!!children} sx={{ overflow: "hidden" }}>
      <StyledAlert
        severity={severity}
        backgroundColor={palette[severity].light}
        borderColor={palette[severity].main}
        icon={severityToIconMap[severity]}
        sx={mergeSx(styles, sx)}
      >
        <StyledTypography>{children}</StyledTypography>
      </StyledAlert>
    </Collapse>
  );
};
