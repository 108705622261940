import { LDClient } from "launchdarkly-react-client-sdk";
import { useEffect } from "react";

import { setFlagsReadyClientId } from "~/app/app.slice";
import { useAppDispatch } from "~/app/store";

export function useSetLdClientId(args: {
  clientId: string | null;
  ldClient: LDClient | undefined;
  fcId: string | undefined;
}) {
  const dispatch = useAppDispatch();

  const { clientId, ldClient, fcId } = args;

  useEffect(() => {
    if (!clientId || !ldClient) return;
    if (fcId) return; // handled by useSetLdFcId

    void (async () => {
      try {
        await ldClient.waitForInitialization(5);
        await ldClient.identify({ kind: "client", key: clientId });
        dispatch(setFlagsReadyClientId(true));
      } catch (err) {
        console.error("Client identify error:", err);
      }
    })();
  }, [clientId, ldClient, fcId, dispatch]);
}
